import {api} from "./api";
import store from "../../state/store";
import {nonActionAxiosInstance} from "./nonActionAxiosInstance";

export async function getStockItemMovementItemCounts({
  itemID,
  startDate,
  warehouseID,
  indicationAvailableOnReturnDate,
}) {
  const result = await api.get(`/v2/screen/stock/details`, {
    params: {
      itemID,
      date: startDate,
      indicationAvailableOnReturnDate: indicationAvailableOnReturnDate,
      warehouse: warehouseID,
    },
  });

  return result.data;
}

export async function getScanBoxItems({searchValue, params}) {
  const result = await nonActionAxiosInstance.post(
    "/Admin/WebServices/CoreWebServices.asmx/MainRadComboBox_GetItems",
    {
      context: {
        Text: searchValue,
        NumberOfItems: 0,
        DiValues: {},
        DiPrimaryKeys: {
          CategoryID: params?.categoryID ?? null,
          CustomerID: params?.customerID ?? null,
          ContactID: params?.contactID ?? null,
          DateTimeExpectedStart: params?.dateTimeExpectedStart ?? null,
          DateTimeExpectedEnd: params?.dateTimeExpectedEnd ?? null,
          Reference: params?.reference ?? null,
          PrimaryKey: null,
          DefaultPeriodID: null,
          "Type !=":
            params?.tablename == "Rental.QuotationItem" ? "ItemSerial" : null,
        },
        TableName:
          params?.subject == "Rental.Reservation"
            ? "Rental.ReservationItem"
            : "Rental.OrderItem",
        ColumnName: "ItemID",
        RowCount: 20,
        Subject: "Rental.virtual_QuickRent",
        Prefix: "Multi",
      },
    },
    {
      params: {
        warehouse: store.state.activeWarehouse,
      },
    },
  );

  return result.data;
}
