<template>
  <button
    :id="id"
    :class="`r-button disabled:cursor-default cursor-pointer mx-[4px] align-middle text-center font-normal whitespace-nowrap select-none inline-block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary shadow-[1px_1px_1px_0px_rgba(0,0,0,0.17)] ${classes} ${buttonSize}`"
    @click="$emit('click', $event)"
    @mousedown="$emit('mousedown', $event)"
    @keyup.enter="handleEnter"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "RButton",
  props: {
    variant: {
      type: String,
      default: "secondary",
      required: false,
    },
    id: {
      type: String,
      default: "",
      required: false,
    },
    size: {
      type: String,
      default: "md",
      required: false,
    },
    overrideClasses: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    classes() {
      switch (this.variant) {
        case "link":
          return `text-primary hover:text-primary-hover bg-transparent font-normal !shadow-none hover:underline focus:border-transparent !border-none ${this.overrideClasses}`;
        case "dark":
          return `text-[#fff] bg-dark hover:bg-dark-hover border-dark hover:border-dark-hover !focus:shadow-none ${this.overrideClasses}`;
        case "info":
          return `text-[#fff] bg-[#17a2b8] border-[#17a2b8] hover:bg-[#138496] hover:border-[#117a8b] active:bg-[#117a8b] active:border-[#10707f] ${this.overrideClasses}`;
        case "primary":
          if (this.isTab)
            return `text-[#fff] ${
              this.active
                ? "bg-[#d54d0d] !border-[#c8490c]"
                : "bg-[#f26522] hover:bg-[#ed560e] border-[#f26522] hover:border-[#d54d0d]"
            } ${this.overrideClasses}`;
          return `text-[#fff] bg-primary border-primary hover-classes ${this.overrideClasses}`;
        case "secondary":
          return `!text-secondary bg-[#eee] hover:bg-secondary-hover active:bg-[#d5d5d5] border-[#eee] hover:border-secondary-hover active:border-[#cecece] ${this.overrideClasses}`;
      }
    },
    buttonSize() {
      switch (this.size) {
        case "xs":
          return "px-[0.25rem] text-[0.875rem]";
        case "sm":
          return "py-[0.25rem] px-[0.5rem] text-[0.875rem] leading-[1.5]";
        case "md":
          return "py-[0.375rem] px-[0.75rem] text-[1rem] leading-[1.4]";
        case "lg":
          return "py-[0.375rem] px-[2rem] text-[1rem] leading-[1.4]";
        default:
          return "py-[0.375rem] px-[0.75rem] text-[1rem] leading-[1.4]";
      }
    },
  },
  methods: {
    handleEnter(event) {
      if (document.activeElement === event.target) {
        this.$emit("click", event);
      }
    },
  },
};
</script>

<style scoped>
.r-button {
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.hover-classes:hover {
  background-color: #e1510d !important;
  border-color: #d54d0d;
}

.hover-classes:focus {
  background-color: #f26522 !important;
}

.hover-classes:focus:hover {
  background-color: #e1510d !important;
}
</style>
