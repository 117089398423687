
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
        const __nunjucks_template_import__dep_1 = require("./table.html");
        
        const __nunjucks_template_import__dep_2 = require("./form/inputField.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/partialdelivery.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<!--";
env.getTemplate("views/elements/combobox.html", false, "views/content/partialdelivery.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "-->\n<!--";
env.getTemplate("views/content/table.html", false, "views/content/partialdelivery.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("table", t_4);
output += "-->\n<!--";
env.getTemplate("views/content/form/inputField.html", false, "views/content/partialdelivery.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("inputfield", t_7);
output += "-->\n<!--<div class=\"content view-partial-delivery\">-->\n<!--\t<form class=\"form-view grid\">-->\n<!--\t\t<input type=\"hidden\" name=\"RentalType\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"formType"), env.opts.autoescape);
output += "\">-->\n<!--\t</form>-->\n\n<!--\t<div class=\"table-view unsortable\">-->\n<!--\t\t<form class=\"table-container\">-->\n<!--\t\t\t";
output += "-->\n<!--\t\t\t<div class=\"table-index sticky-div\">-->\n<!--\t\t\t\t<div class=\"table-row\">-->\n<!--\t\t\t\t\t";
output += "-->\n<!--\t\t\t\t\t<label class=\"table-cell checkbox selection-box\">-->\n<!--\t\t\t\t\t\t<div class=\"ellipsis\">-->\n<!--\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"EnableSelectAll")) {
output += "-->\n<!--\t\t\t\t\t\t\t\t<input type=\"checkbox\" title=\"Select all\">-->\n<!--\t\t\t\t\t\t\t";
;
}
output += "-->\n<!--\t\t\t\t\t\t</div>-->\n<!--\t\t\t\t\t</label>-->\n\n<!--\t\t\t\t\t";
frame = frame.push();
var t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("col", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
output += "-->\n<!--\t\t\t\t\t\t<div -->\n<!--\t\t\t\t\t\tclass=\"table-cell ";
output += runtime.suppressValue((runtime.memberLookup((t_13),"Type") == "Money" || runtime.memberLookup((t_13),"Type") == "Decimal"?"number " + runtime.memberLookup((t_13),"Type"):""), env.opts.autoescape);
output += "\" -->\n<!--\t\t\t\t\t\tdata-column=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"Name"), env.opts.autoescape);
output += "\" -->\n<!--\t\t\t\t\t\ttitle=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"Title"), env.opts.autoescape);
output += "\">-->\n<!--\t\t\t\t\t\t\t<div class=\"ellipsis\">-->\n<!--\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_13),"Title"), env.opts.autoescape);
output += "-->\n<!--\t\t\t\t\t\t\t</div>-->\n<!--\t\t\t\t\t\t</div>-->\n<!--";
;
}
}
frame = frame.pop();
output += "-->\n\n<!--\t\t\t\t\t<div class=\"table-cell dropdown-info\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"UncollapseAll"), env.opts.autoescape);
output += "\">-->\n<!--\t\t\t\t\t\t<div class=\"ellipsis\" data-show-all-lower-fields>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"UncollapseAll"), env.opts.autoescape);
output += "</div>-->\n<!--\t\t\t\t\t</div>-->\n<!--\t\t\t\t</div>-->\n<!--\t\t\t</div>-->\n\n<!--\t\t\t";
output += "-->\n<!--\t\t\t<div class=\"table-body bulkedit\">-->\n<!--\t\t\t\t<div class=\"table-rows\">-->\n<!--\t\t\t\t\t<div class=\"table-row-group\">-->\n<!--                        <div class=\"new-row\">-->\n<!--\t\t\t\t\t\t<div class=\"table-row \">-->\n<!--\t\t\t\t\t\t\t";
output += "-->\n<!--\t\t\t\t\t\t\t<label class=\"table-cell checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" title=\"Select\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "row")),"selected")?"checked":""), env.opts.autoescape);
output += "></label>-->\n\n<!--\t\t\t\t\t\t\t";
frame = frame.push();
var t_16 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns");
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("col", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
output += "-->\n<!--\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 53, colno = 34, runtime.callWrap(runtime.memberLookup((t_4),"setColumnCell"), "table[\"setColumnCell\"]", context, [t_17,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"",runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "-->\n<!--\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "-->\n<!--\t\t\t\t\t\t\t\t<div class=\"table-cell dropdown-info material-icons\" data-toggle-lower-field>&#xe313;</div>-->\n<!--\t\t\t\t\t\t\t</div>-->\n\n<!--\t\t\t\t\t\t\t<tr>-->\n<!--\t\t\t\t\t\t\t\t<td class=\"lower-field hide\" colspan=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns")),"length") + 2, env.opts.autoescape);
output += "\">-->\n<!--\t\t\t\t\t\t\t\t\t<form class=\"form-view grid\">-->\n<!--\t\t\t\t\t\t\t\t\t\t<div class=\"row no-gutters lower-field hide\">-->\n<!--\t\t\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_20 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ColumnsExtra");
if(t_20) {t_20 = runtime.fromIterator(t_20);
var t_19 = t_20.length;
for(var t_18=0; t_18 < t_20.length; t_18++) {
var t_21 = t_20[t_18];
frame.set("col", t_21);
frame.set("loop.index", t_18 + 1);
frame.set("loop.index0", t_18);
frame.set("loop.revindex", t_19 - t_18);
frame.set("loop.revindex0", t_19 - t_18 - 1);
frame.set("loop.first", t_18 === 0);
frame.set("loop.last", t_18 === t_19 - 1);
frame.set("loop.length", t_19);
output += "-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"col col-xs-12 col-sm-12 col-md-6\">-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t<label class=\"form-field\">-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_21),"Title"), env.opts.autoescape);
output += "-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_21),"IsRequired")) {
output += "-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"required-indicator\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\">*</span>-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
output += "-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t\t</div>-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"form-input required-field ";
output += runtime.suppressValue((runtime.memberLookup((t_21),"Type") == "Money"?"money":""), env.opts.autoescape);
output += "\">-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 71, colno = 49, runtime.callWrap(runtime.memberLookup((t_7),"createInputField"), "inputfield[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_21,runtime.memberLookup((t_21),"Type"),null,runtime.memberLookup((t_21),"Initial"),null,null,runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += "-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t\t</div>-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t\t</label>-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t</div>-->\n<!--\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "-->\n<!--\t\t\t\t\t\t\t\t\t\t\t</div>-->\n<!--\t\t\t\t\t\t\t\t\t\t</form>-->\n<!--\t\t\t\t\t\t\t\t\t</td>-->\n<!--\t\t\t\t\t\t\t\t</tr>-->\n\n<!--\t\t\t\t\t\t\t</div>-->\n<!--                </div>-->\n<!--\t\t\t\t\t\t</div>-->\n<!--\t\t\t\t\t</div>-->\n<!--\t\t\t\t</form>-->\n\n<!--\t\t\t\t";
output += "-->\n<!--\t\t\t\t<div class=\"table-footer clearfix sticky-div stick-to-bottom\">-->\n<!--\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">-->\n<!--\t\t\t\t\t\t<span data-select-count title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"SelectCount"), env.opts.autoescape);
output += "\">0</span>-->\n<!--\t\t\t\t\t</div>-->\n\n<!--\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"product") == "RentMagic") {
output += "-->\n<!--\t\t\t\t\t\t<div class=\"pull-right\">-->\n<!--\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">-->\n<!--\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalPriceExcVAT"), env.opts.autoescape);
output += ": <span data-total-price>0.00</span>-->\n<!--\t\t\t\t\t\t\t</div>-->\n\n<!--\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">-->\n<!--\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalVAT"), env.opts.autoescape);
output += ": <span data-total-btw>0.00</span>-->\n<!--\t\t\t\t\t\t\t</div>-->\n\n<!--\t\t\t\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">-->\n<!--\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalPriceIncVAT"), env.opts.autoescape);
output += ": <span data-total-btw-price>0.00</span>-->\n<!--\t\t\t\t\t\t\t</div>-->\n<!--\t\t\t\t\t\t</div>-->\n<!--\t\t\t\t\t";
;
}
output += "-->\n<!--\t\t\t\t</div>-->\n<!--\t\t\t</div>-->\n<!--\t\t</div>-->";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/partialdelivery.html", ctx);
          }
        
          return nunjucks.render("views/content/partialdelivery.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/partialdelivery.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        