import $ from "jquery";
import {alertPopup} from "../../interface/alertPopup/alertPopup.js";
import formatter from "../formatter.js";
import serializeForm from "../../util/serializeForm.js";
import {runMatchedClientSideActions} from "../../actions/legacy/runMatchedClientSideActions.js";
import {getActiveVueComponentFromWindow} from "../../functions/window/getActiveVueComponentFromWindow.js";
import {hasMissingRequiredFields} from "../../functions/form/hasMissingRequiredFields.js";
import {getTranslations} from "../../functions/session/localstorage/getTranslations.js";
import {parseJson} from "../../functions/parsing/parseJson.js";

/**
 * Run action
 * @param {string} name - Action name
 * @param {string} targetTableName  - Name of target table
 * @param {string} webserviceUrl - Service URL
 * @param {string} webMethodName - Webmethod name
 * @param {boolean} doNotUseInternal - Should we use internal?
 * @returns {Promise} Promise
 */
export default async function action(
  name,
  targetTableName,
  webserviceUrl,
  webMethodName,
  doNotUseInternal,
) {
  this.toggleLoading(true);
  const translations = getTranslations();

  let actionName = name.replace(/\|/g, ":");
  if (!doNotUseInternal) {
    this.lastActionArgs = [
      actionName,
      targetTableName,
      webserviceUrl,
      webMethodName,
      doNotUseInternal,
    ];
  }

  let button = null;
  let tab = null;
  let selectedTab = null;

  if (
    actionName === "Save" &&
    hasMissingRequiredFields({activeWindow: this, translations})
  ) {
    return this.toggleLoading(false);
  }

  if (this.output.Buttons && this.output.Tabs) {
    button = this.output.Buttons.filter(
      (x) => x.ActionName == actionName,
    ).pop();
    tab =
      button ||
      this.output.Tabs.filter((x) => x.ActionName == actionName).pop();
  }
  if (tab) {
    this.tabs.forEach((x) => {
      x.Selected = tab === x;
    });

    // Store ActionName in input.Data.SelectedTabActionName to keep selected tab after reset
    if (this.output.Tabs.length > 0) {
      selectedTab = this.output.Tabs.filter(
        (x) => x.ActionName == actionName,
      ).pop();
      this.input.Data.SelectedTabActionName =
        selectedTab != undefined ? selectedTab.ActionName : null;
    }
  }

  let spec = button || tab;

  let customFn = this["action" + actionName];

  if (typeof customFn === "function" && !doNotUseInternal) {
    let result = customFn.call(
      this,
      targetTableName,
      webserviceUrl,
      webMethodName,
      true,
    );
    await Promise.resolve(result);
    this.toggleLoading(false);
    return;
  }

  const activeVueInstanceComponent = getActiveVueComponentFromWindow({
    window: this,
  });

  const vueFunction = activeVueInstanceComponent?.[`customWindow${actionName}`];

  if (vueFunction) {
    this.toggleLoading(true);
    await vueFunction();
    this.toggleLoading(false);
    return;
  }

  // Move primitive properties from customData into actionInputArgs
  if (this.customData) {
    for (var property in this.customData) {
      if (typeof this.customData[property] !== "object") {
        this.actionInputArgs[property] = this.customData[property];
      }
    }
  }

  if (this.sub && this.sub.window) {
    if (
      this.sub.window.output &&
      this.sub.window.output.Request.Data &&
      this.sub.window.output.Request.Data.ClientCurrency
    ) {
      this.output.Request.Data.ClientCurrency =
        this.sub.window.output.Request.Data.ClientCurrency;
    }
  }

  let opts = {
    selectionInfo: {
      ActionName: actionName,
      Arguments: this.actionInputArgs || {},
      Request: this.output.Request,
      Data: {
        Selection: !selectedTab ? this.selection : this.sub.window.selection,
      },
    },
  };

  this.actionInputArgs = {};

  // if (spec != null && spec.SendData != null && spec.SendData.indexOf("Changes") >= 0) {
  if (this.output.Table != null) {
    let changes = [];

    for (
      let rowIndex = 0;
      rowIndex < this.output.Table.Rows.length;
      rowIndex++
    ) {
      let formData = {};
      let criteria =
        this.output.Request.Prefix != "New"
          ? this.buildCriteriaNew(this.output.FullTable.Rows[rowIndex])
          : null;

      for (let cell of this.output.FullTable.Rows[rowIndex]) {
        let col = cell.Column;
        let cname = col.Name;
        let windowElement = this.element;
        let isSaveableReadonlyCol = false;

        let elementValue = $(windowElement)
          .find(`[vue-input][name='${cname}']`)
          .val();

        if (
          col.Editor === "autoIncrement" &&
          elementValue !== translations.NewFormAutoincrementingDescription
        )
          isSaveableReadonlyCol = true;

        if (this.sub) {
          windowElement = this.sub.window.element;
        }

        if (isSaveableReadonlyCol && elementValue?.length < 1) {
          await alertPopup({
            icon: "error",
            text: translations.AdminDesktop_AlertFillRequiredFields,
          });
          return;
        }

        if (
          col.IsAutoNumber &&
          this.output.Request.Prefix === "New" &&
          !isSaveableReadonlyCol
        ) {
          formData[cname] = null;
          continue;
        }

        if (col.IsReadOnly && !isSaveableReadonlyCol) {
          continue;
        }

        let formValue = cell.NewValue;

        if (col.Type === "Boolean" && formValue === null) formValue = false;
        if (col.Name === "Color" && formValue === null) formValue = "#f26522";

        if (
          col.Type === "Object" &&
          formValue !== null &&
          formValue !== undefined
        ) {
          formValue = parseJson(formValue);
        }
        if (
          cell?.Value !== elementValue &&
          elementValue !== undefined &&
          elementValue !== null &&
          elementValue !== ""
        ) {
          formData[cname] = formatter.serializeValue(col, elementValue);
          continue;
        }

        if (
          !cell.IsDirty &&
          this.output.Request.Prefix != "New" &&
          this.output.Request.Prefix != "Preview" &&
          this.output.Request.Prefix != "Multi"
        ) {
          continue;
        }

        formData[cname] = formatter.serializeValue(col, formValue);
      }
      changes.push({Criteria: criteria, Values: formData});
    }

    opts.selectionInfo.Data.Changes = changes;
  }

  if (
    spec != null &&
    spec.SendData != null &&
    spec.SendData.indexOf("Form") >= 0
  ) {
    let form = serializeForm(this.element);

    for (let x in form) {
      if (String(form[x]).length < 1) {
        delete form[x];
        continue;
      }
    }

    if (this.output.Data != null && this.output.Data.Type == "tiles") {
      delete form.scan;
    }

    opts.selectionInfo.Data.Form = form;
  }

  opts.ExtraData = global.session.activeWindow.extraData;

  let result;
  this.toggleLoading(true);
  try {
    result = await this.session.goodRequest({
      uri:
        (webserviceUrl || "/Admin/WebServices/CoreWebServices.asmx") +
        "/" +
        webMethodName,
      postData: opts,
      windowId: this.id,
    });

    if (result?.ExtraData) {
      global.session.activeWindow.extraData = result.ExtraData;
    }
  } catch (error) {
    let errorMessage;
    if (error.message != undefined) {
      errorMessage = error.message;
    } else if (error.data.Error != undefined) {
      errorMessage = error.data.Error;
    } else if (error.data.Message != undefined) {
      errorMessage = error.data.Message;
    } else if (error.statusText != undefined) {
      errorMessage = error.statusText;
    }

    this.message("error", errorMessage);
    console.error(error);
    return;
  }

  if (webMethodName === "SaveData") {
    $(this.element).find(".form-field").removeClass("dirty");
  }

  this.actionInputArgs = {};

  await runMatchedClientSideActions({
    targetTableName,
    webMethodName,
    webserviceUrl,
    window: this,
    afterAction: true,
  });

  await this.handleActionResponse(result);

  this.toggleLoading(false);
}
