import {getTemplateFunctionObject} from "./template/getTemplateFunctionObject.js";
import templateMapping from "./templater/templateMapping";
import TreeView from "../model/treeview";

/**
 * Window template generating
 */
class Templater {
  /**
   * Available templates
   * @type {Object}
   * @property {function} empty - empty template
   * @property {function} table - Table view
   * @property {function} form - Form view
   * @property {function} iframe - Iframe view
   * @property {function} window - Base window template(most likely includes another template)
   * @property {function} tree - Treeview template
   * @property {function} rentform - Quickrent form
   * @property {function} quickscanform - Quickscan form
   * @property {function} quickeditform - Quickedit form
   * @property {function} returnview - Return view
   * @property {function} upload - Upload view
   * @property {function} permissions - Permissions view
   * @property {function} dimensionobjects - Dimension objects view
   * @property {function} dateview - Date view
   * @property {function} tiles - Tiles view
   * @property {function} closeup - Close up view
   * @property {function} timeline - Timeline view
   * @property {function} startup - Startup window view
   * @example template({ window, data, custom})
   */
  windowTemplates = {
    empty: require("../../views/content/empty.html"),
    table: require("../../views/content/table.html"),
    form: require("../../views/content/form.html"),
    window: require("../../views/elements/window.html"),
    tree: require("../../views/content/treeview.html"),
    rentform: require("../../views/content/quickrent.html"),
    quickscanform: require("../../views/content/quickscan.html"),
    quickeditform: require("../../views/content/quickedit.html"),
    returnview: require("../../views/content/return.html"),
    upload: require("../../views/content/upload.html"),
    startup: require("../../views/content/startup.html"),
    permissions: require("../../views/content/permissions.html"),
    dimensionobjects: require("../../views/content/dimensionobjects.html"),
    horizontalagenda: require("../../views/elements/horizontalCalender.html"),
    dateview: require("../../views/content/dateview.html"),
    tiles: require("../../views/content/tiles.html"),
    closeup: require("../../views/content/closeup.html"),
    timeline: require("../../views/content/timeline.html"),
    dashboard: require("../../views/content/dashboard.html"),
    vueTemplate: require("../../views/content/vueTemplate.html"),
    dynamicdashboard: require("../../views/content/dynamicdashboard.html"),
    pick: require("../../views/content/pick.html"),
    partialdelivery: require("../../views/content/partialdelivery.html"),
  };

  componentTemplates = {
    comboboxSelection: require("../../views/elements/comboboxSelection.html"),
  };

  vueSupportedTemplates = [
    "iframe",
    "dashboard",
    "dynamicDashboard",
    "diagramDashboard",
    "startup",
    "placeholder",
    "kanbanboard",
  ];

  /**
   * Render window html from window object
   * @param {Window} window - Window object
   * @returns {string} HTML
   */
  renderWindow(window) {
    // empty base template
    let _template = this.windowTemplates.empty;
    let templateName = "";
    let randomVueID = `vue-${Math.floor(Math.random() * Math.floor(900000))}`;

    // set template based on output type DECPRECATED
    if (
      window.output.Data !== null &&
      this.windowTemplates[window.output.Data.Type]
    ) {
      _template = this.windowTemplates[window.output.Data.Type];
      templateName = window.output.Data.Type;
    }

    // Also check lower case type since if statement above is being deprecated
    if (
      window.output.Data !== null &&
      this.windowTemplates[window.output.Data.type]
    ) {
      _template = this.windowTemplates[window.output.Data.type];
      templateName = window.output.Data.Type;
    }

    // override if window has custom template
    if (
      window.customTemplateName !== null &&
      this.windowTemplates[window.customTemplateName]
    ) {
      _template = this.windowTemplates[window.customTemplateName];
      templateName = window.customTemplateName;
    }

    if (
      this.vueSupportedTemplates.includes(
        window.output.Data?.Type || window.customTemplateName,
      )
    ) {
      _template = this.windowTemplates.vueTemplate;
      templateName = "vueTemplate";

      window.vueTemplate = true;
      window.vueElementID = randomVueID;
    }

    // if template is treeview, make sure we have a tree
    if (_template === this.windowTemplates.tree) {
      // Set edititems and initialvalues
      window.output.Data.editItems = false;
      window.output.Data.initialValues = {};

      for (let i = 0; i < window.output.Data.Rows.length; i++) {
        if (window.output.Data.Rows[i].Attributes.Available !== undefined) {
          window.output.Data.initialValues[window.output.Data.Rows[i].Value] =
            window.output.Data.Rows[i].Attributes.Available;
          window.output.Data.editItems = true;
        }
      }

      // generate a [text, value, parentkeyAttribute, availableAttribute] array for each row
      // then pass that through the treeview generator function
      let tree = TreeView.fromRows(
        window.output.Data.Rows.map((x) => [
          x.Text,
          x.Value,
          x.Attributes[window.output.Data.ParentKey],
          x.Attributes.Available,
          x.Attributes[window.output.Data.ParentKey] !== undefined,
        ]),
        2,
        1,
      );

      TreeView.sort(tree, 0);

      // set output
      window.output.Data.tree = tree;

      if (!window.output.Data.editItems) {
        // insert sub window
        window.insertWindow({}, true, false, true);
      }
    }

    const targetWindow = window.sub?.window ?? window;

    const templateMap = templateMapping.find(
      (template) =>
        template.Subject === targetWindow.output.Request.Subject &&
        template.Prefix === targetWindow.output.Request.Prefix,
    );

    if (templateMap) {
      _template = this.windowTemplates.vueTemplate;
      templateName = "vueTemplate";
      targetWindow.vueTemplate = true;
      targetWindow.template = templateMap.template;
      targetWindow.vueElementID = randomVueID;
    }

    let body = _template({
      window: window,
      data: window.output,
      custom: window.customData,
      templateFunctions: getTemplateFunctionObject({templateName}),
    });

    let windowHtml = this.windowTemplates.window({
      window: window,
      data: window.output,
      body: body,
      custom: window.customData,

      // This next line creates an array containing all sub windows if any
      // Filters all null objects, then renders the html for each
      childrenHtml: []
        .concat(window.sub)
        .filter(Boolean)
        .map((child) => child.window.html()),
    });

    return windowHtml;
  }

  refreshComponent(template, data) {
    return this.componentTemplates[template](data);
  }
}

/** @ignore */
export default new Templater();
