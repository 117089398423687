import $ from "jquery";
import store from "../../../state/store";
import Hook from "@/hook";
import clone from "clone";

/**
 * Fetch data from server, process and render window
 * @returns {Promise} Promise
 */
export default async function fetch(rerender = false, extraParams) {
  $("body").addClass("avoid-clicks");
  // if there is no input, we cannot get data from server, exit
  if (!this.input) {
    this.loading = false;
    $("body").removeClass("avoid-clicks");
    return await this.render();
  }

  // reset error and set loading
  this.loading = true;
  this.error = null;
  if (!window.vueInstance) {
    this.render({rerender});
  }
  store.commit("updateWindow");

  this.rerender = rerender;
  return await Hook.run("fetch", this, async () => {
    // send request and process output
    const fetchType = this.parent ? "SubWindow" : "Window";

    let url = `/Admin/WebServices/CoreWebServices.asmx/Open${fetchType}`;
    this.output = await this.session.request(
      url,
      {
        request: this.input,
      },
      extraParams,
    );

    await this.process(this.output);

    // store values for later
    this.lastSuccessInput = clone(this.input);

    // we are done loading, render output
    this.loading = false;
    if (!window.vueInstance) {
      await this.render({rerender: this.rerender});
    }

    $("body").removeClass("avoid-clicks");
    store.commit("updateWindow");
  });
}
