<template>
  <div v-if="showDialog">
    <r-modal
      v-model="showDialog"
      :show="showDialog"
      :size="size"
      :title="title"
      :variant="variant"
      @close="hideDialog"
      @hide="hideDialog"
    >
      <component :is="template" v-if="showDialog" :dialog-data="dialogData">
      </component>
      <template #modal-footer>
        <component
          :is="footerTemplate"
          v-if="footerTemplate"
          :dialog-data="dialogData"
          class="flex justify-end"
        />

        <dialog-close-button v-else class="w-100" />
      </template>
    </r-modal>
  </div>
</template>

<script>
import DialogCloseButton from "./DialogCloseButton";
import RModal from "@/components/elements/RModal";

export default {
  name: "Dialog",
  components: {DialogCloseButton, RModal},
  provide() {
    return {
      translations: global.session.translations,
    };
  },
  computed: {
    showDialog() {
      return this.$store.getters["dialog/show"];
    },
    templateName() {
      return this.$store.getters["dialog/getTemplate"] ?? "Form";
    },
    template() {
      if (this.templateName === "") return null;
      return () => import(`./Dialog${this.templateName}.vue`);
    },
    footerTemplate() {
      if (this.templateName === "") return null;

      try {
        const template =
          import(`./Dialog${this.templateName}Footer.vue`) ?? null;
        return () => template;
      } catch (e) {
        return null;
      }
    },
    title() {
      return this.$store.getters["dialog/getTitle"];
    },
    dialogData() {
      return this.$store.getters["dialog/getData"];
    },
    size() {
      return this.dialogData.DialogSize ?? "sm";
    },
    variant() {
      return this.dialogData.DialogVariant ?? "primary";
    },
  },
  methods: {
    hideDialog() {
      this.$store.commit("dialog/hideDialog");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
