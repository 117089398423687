<template>
  <div v-if="show">
    <r-modal
      v-if="show"
      size="xl"
      :show="show"
      :show-footer="false"
      :title="`${event.args.fields[5]} - ${translations.ModalSendMailTitle}`"
      @hide="show = false"
    >
      <form @submit.prevent="submit">
        <div class="modal-body mail-send-modal">
          <div class="container-fluid">
            <div
              id="notifyDialog"
              style="display: none"
              class="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              <span id="notifyDialogContent"></span>
              <button
                type="button"
                class="close"
                data-dismiss="alertPopup"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="row">
              <div
                class="col col-xl-4 col-sm-12 list-contacts"
                style="height: 100%"
              >
                <!-- Report Template -->
                <div class="col">
                  <label class="form-field">
                    <div
                      :title="translations.modalMailSendReportTemplateLabel"
                      class="form-label"
                    >
                      {{ translations.modalMailSendReportTemplateLabel }}:
                    </div>
                    <div class="form-input">
                      <v-select
                        :value="form.selectedReportTemplate"
                        label="Text"
                        :options="reportTemplates"
                        @input="
                          handleChange({
                            $event,
                            field: 'selectedReportTemplate',
                          })
                        "
                      />
                    </div>
                  </label>
                </div>

                <!-- Languages -->
                <div class="col">
                  <label class="form-field">
                    <div :title="event.args.fields[2].Title" class="form-label">
                      {{ event.args.fields[2].Title }}:
                    </div>
                    <div class="form-input">
                      <v-select
                        :value="form.languageId"
                        label="Text"
                        :options="languages"
                        @input="handleChange({$event, field: 'languageId'})"
                      />
                    </div>
                  </label>
                </div>

                <!-- Mail Template -->
                <div class="col">
                  <label class="form-field">
                    <div
                      :title="translations.modalMailSendMailTemplateLabel"
                      class="form-label"
                    >
                      {{ translations.modalMailSendMailTemplateLabel }}:
                    </div>
                    <div class="form-input">
                      <v-select
                        :value="form.selectedEmailTemplate"
                        label="Text"
                        :options="emailTemplates"
                        @input="
                          handleChange({$event, field: 'selectedEmailTemplate'})
                        "
                      />
                    </div>
                  </label>
                </div>

                <!-- TO Recipients -->
                <div class="col">
                  <div class="form-field">
                    <div
                      :title="translations.modalMailSendAddEmail"
                      class="form-label"
                    >
                      {{ translations.modalMailSendAddEmail }}:
                      <span title="" class="required-indicator">*</span>
                    </div>

                    <div class="form-input">
                      <v-select
                        :value="form.recipients"
                        multiple
                        :clearable="false"
                        taggable
                        push-tags
                        :clear-search-on-blur="vSelectBlur"
                        :clear-search-on-select="true"
                        :options="contacts"
                        @input="handleChange({$event, field: 'recipients'})"
                      >
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="form.recipients.length < 1"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>

                <!-- CC Recipients -->
                <div class="col">
                  <div class="form-field">
                    <div
                      :title="`${translations.modalMailSendAddEmail} (CC)`"
                      class="form-label"
                    >
                      {{ translations.modalMailSendAddEmail }} (CC):
                    </div>
                    <div class="form-input">
                      <v-select
                        :value="form.optionalRecipients"
                        multiple
                        taggable
                        push-tags
                        :clear-search-on-blur="vSelectBlur"
                        :options="contacts"
                        @input="
                          handleChange({$event, field: 'optionalRecipients'})
                        "
                      />
                    </div>
                  </div>
                </div>

                <!-- BCC Recipients -->
                <div class="col">
                  <div class="form-field">
                    <div
                      :title="`${translations.modalMailSendAddEmail} (BCC)`"
                      class="form-label"
                    >
                      {{ translations.modalMailSendAddEmail }} (BCC):
                    </div>
                    <div class="form-input">
                      <v-select
                        :value="form.optionalHiddenRecipients"
                        multiple
                        taggable
                        :clear-search-on-blur="vSelectBlur"
                        push-tags
                        :options="contacts"
                        @input="
                          handleChange({
                            $event,
                            field: 'optionalHiddenRecipients',
                          })
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col-xl-8 col-sm-12">
                <div class="col col-xs-12">
                  <text-field
                    :value="form.subject"
                    :max-length="250"
                    :label="translations.EmailSubject"
                    :required="true"
                    @input="(e) => (form.subject = e.target.value)"
                  />
                </div>

                <div class="col col-xs-12">
                  <rich-text-editor-field
                    :field="form.richTextEditorField"
                    @input="handleChange({$event, field: 'body'})"
                  />
                </div>
              </div>
            </div>
            <!--/row-->
          </div>
          <!-- /container -->
        </div>
        <div class="modal-footer pb-0">
          <div class="msg-actions text-">
            <button class="btn btn-primary mr-auto" type="submit">
              <i v-if="loading" class="fas fa-spinner fa-spin"></i>
              {{ translations.ButtonSendMailTitle }}
            </button>
          </div>
        </div>
      </form>
    </r-modal>
  </div>
</template>

<script>
import RichTextEditorField from "../../../src/components/form/fields/RichTextEditorField.vue";
import TextField from "../form/TextField.vue";
import RModal from "@/components/elements/RModal";
import {sendMail} from "@/services/mail";
import VSelect from "vue-select";

export default {
  components: {
    RichTextEditorField,
    TextField,
    VSelect,
    RModal,
  },
  props: {
    event: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        subject: "",
        recipients: [],
        cc: [],
        bcc: [],
        reportTemplate: null,
        body: "",
        emailTemplate: null,
        fullTableName: null,
        languageId: null,
        primaryKey: null,
        primaryKeys: null,
        optionalRecipients: [],
        optionalHiddenRecipients: [],
        selectedReportTemplate: null,
        selectedEmailTemplate: null,
        richTextEditorField: {},
      },
      subject: "",
      loading: false,
      editor: null,
      show: false,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    emailTemplates() {
      return this.event.args.fields[3].DropdownItems;
    },
    languages() {
      return this.event.args.fields[2].DropdownItems;
    },
    reportTemplates: function () {
      return this.event.args.fields[1].DropdownItems;
    },
    contacts: function () {
      return this.event.args.fields[4].DropdownItems.map(
        (contact) => contact.Value,
      );
    },
  },
  watch: {
    "form.selectedEmailTemplate": function (val) {
      this.form.subject = val?.Attributes.Subject;
      this.subject = val?.Attributes.Subject;
      this.form.emailTemplate = val?.Value;
    },
    "form.selectedReportTemplate": function (val) {
      this.form.reportTemplate = val?.Value;
    },
    "form.subject": function (val) {
      this.form.subject = val;
    },
  },
  async mounted() {
    this.show = true;
  },
  created() {
    this.form.languageId = this.event.args.fields[2].Initial;
    this.form.primaryKey = this.event.args.fields[7].PrimaryKey[0];
    this.form.fullTableName = this.event.args.fields[6].Subject;
    this.form.primaryKeys = this.event.args.fields[5];
    this.form.recipients = this.event.args.fields[8].To;
    this.form.optionalRecipients = this.event.args.fields[9].Cc;
    this.form.optionalHiddenRecipients = this.event.args.fields[10].Bcc;
    this.form.selectedReportTemplate =
      this.event.args.fields[1]?.Initial ?? this.reportTemplates[0];
    this.form.selectedEmailTemplate =
      this.event.args.fields[3]?.Initial ?? this.emailTemplates[0];
    this.form.body = this.form.selectedEmailTemplate?.Attributes?.Body ?? "";
    this.form.Subject =
      this.form.selectedEmailTemplate.Attributes.Subject ?? "";
    this.form.richTextEditorField = {
      Value: this.form.body,
    };
  },
  methods: {
    handleChange({$event, field}) {
      this.form = {
        ...this.form,
        [field]: $event?.Value ?? $event,
      };
    },
    submit: async function () {
      if (this.form.recipients.length < 1) return;
      this.loading = true;
      const result = await sendMail({...this.form});

      global.session.activeWindow.handleActionResponse(result);

      this.show = false;
      this.loading = false;
    },
    vSelectBlur() {
      return true;
    },
  },
};
</script>
<style lang="scss">
.v-select .vs__dropdown-toggle {
  height: 31px !important;
}

.mail-send-modal {
  .form-input .vs__dropdown-toggle {
    border: 1px solid #ced4da;
  }
  .vs__selected-options {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0 2px;
    overflow: hidden;
    position: relative;
    flex-direction: row;

    input {
      box-shadow: none;
    }
  }

  .v-select .vs__dropdown-toggle {
    height: auto !important;
    background: white;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 0 rgb(0 0 0 / 9%) !important;
  }
}
</style>
