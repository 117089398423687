<template>
  <div>
    <RAlertPopup
      v-if="alert"
      :buttons="alert.buttons"
      :markdown="alert.markdown"
      :icon="alert.icon"
      :icon-additional-classes="alert.iconAdditionalClasses"
      :resolve="alert.resolve"
      @close="handleRemoveAlert($event)"
    />
  </div>
</template>

<script>
import {addAlert} from "../../interface/alertPopup/addAlert";
import {getFirstAlert} from "../../interface/alertPopup/getFirstAlert";
import {removeAlert} from "../../interface/alertPopup/removeAlert";
import RAlertPopup from "../elements/RAlertPopup.vue";

export default {
  name: "AlertPopupManager",
  components: {RAlertPopup},
  data: () => ({
    alerts: [],
  }),
  computed: {
    alert() {
      return getFirstAlert({alerts: this.alerts});
    },
  },
  created() {
    global.eventBus.$on("alert", this.handleAddAlert);
  },
  destroyed() {
    global.eventBus.$off("alert", this.handleAddAlert);
  },
  methods: {
    handleAddAlert(alert) {
      this.alerts = addAlert({alerts: this.alerts, alert});
    },
    handleRemoveAlert(alertToRemove) {
      this.alerts = removeAlert({alerts: this.alerts, alertToRemove});
    },
  },
};
</script>

<style scoped></style>
