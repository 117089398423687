export const getExtraCriteriaFromDropdown = ({field, row}) => {
  const primaryKeys = {};
  for (const criteria of field.Dropdown.ExtraCriteria) {
    try {
      primaryKeys[criteria.PrimaryKeyName] = criteria?.SendRowValue
        ? getRowValue({row, criteria})
        : criteria.Value;
    } catch (e) {
      console.error(
        `Unable to get value for key: ${criteria.PrimaryKeyName}, it is likely not present in the row data.`,
      );
    }
  }

  return primaryKeys;
};

function getRowValue({row, criteria}) {
  const columnData = row[criteria.FormColumnName];
  if (columnData && typeof columnData === "object" && "Value" in columnData) {
    return columnData.Value;
  }
  return columnData;
}
