import {getOptions} from "./getOptions.js";

export const processColumnCriteria = async ({vueColumns, formRow, column}) => {
  const newOptions = await getOptions({
    field: column,
    row: formRow.reduce((acc, field) => {
      acc[field.Column.Name] = field.NewValue ?? field.Value;
      return acc;
    }, {}),
  });

  const affectedRowCell = formRow.find(
    (cell) => cell.Column.Name === column.Name,
  );

  const newColumnOptionsIncludeSelectedValue = newOptions.some(
    (option) =>
      option.Value === affectedRowCell.NewValue ?? affectedRowCell.Value,
  );

  const affectedSelectField =
    vueColumns[column.Name].$children[0].$children[0].$children[0];

  if (!affectedSelectField) return;

  if (!newColumnOptionsIncludeSelectedValue) {
    affectedSelectField.options = [];
    affectedSelectField.handleInput({
      Value: null,
    });
  }

  if (newColumnOptionsIncludeSelectedValue) {
    affectedSelectField.options = newOptions;
  }
};
