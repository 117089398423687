import {handleSettingsSave} from "@/actions/legacy/handleSettingsSave.js";

const actions = [
  {
    targetTableName: "Core.Settings",
    webMethodName: "_save",
    actionFunction: handleSettingsSave,
    afterAction: true,
  },
];

export async function runMatchedClientSideActions({
  targetTableName,
  webMethodName,
  webserviceUrl,
  window,
  afterAction,
}) {
  const matchedActions = actions.filter(
    (action) =>
      action.targetTableName === targetTableName &&
      action.webMethodName === webMethodName &&
      action.afterAction === afterAction,
  );

  if (matchedActions.length > 0) {
    for (const action of matchedActions) {
      await action.actionFunction(window);
    }
  }
}
