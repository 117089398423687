<template>
  <div>
    <rich-text-editor
      :value="value"
      :read-only="readonly"
      @input="handleInput"
    />
  </div>
</template>

<script>
import RichTextEditor from "../../elements/RichTextEditor.vue";

export default {
  name: "RichTextEditorField",
  components: {
    RichTextEditor,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    value() {
      return this.field.Value;
    },
    readonly() {
      return (
        this.field.IsReadOnly ?? this.field["Read-only"] === "true" ?? false
      );
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", {Value: value});
    },
  },
};
</script>
