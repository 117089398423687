import {api} from "../api";

export default async function getMetaData({
  table,
  prefix,
  primaryKey,
  criteria,
}) {
  const data = criteria ? criteria : primaryKey ? [{[primaryKey]: "-1"}] : [];

  const result = await api.post(`/v2/metadata/${prefix}`, data, {
    params: {
      table,
    },
  });

  return result.data;
}
