import $ from "jquery";
export const updateLegacyFormFieldObjectsWithActionResponseData = ({
  newMetadata,
  vueColumns,
  newColumns,
}) => {
  for (const column in vueColumns) {
    if (
      newColumns[column]?.Name === vueColumns[column].$children[0].field?.name
    ) {
      vueColumns[column].$children[0].field["read-only"] = convertToString(
        newColumns[column].IsReadOnly ?? "",
      );
      vueColumns[column].$children[0].field.visible = convertToString(
        newColumns[column].IsVisible ?? "",
      );
      vueColumns[column].$children[0].field.min = convertToString(
        newColumns[column].MinNumber ?? "",
      );
      vueColumns[column].$children[0].field.max = convertToString(
        newColumns[column].MaxNumber ?? "",
      );
      vueColumns[column].$children[0].field.postback = convertToString(
        newColumns[column].IsPostback ?? "",
      );

      if (newMetadata[0]?.[newColumns[column].Name]?.IsReadOnly) {
        vueColumns[column].$children[0].field["read-only"] = convertToString(
          newMetadata[0][newColumns[column].Name].IsReadOnly,
        );
      }

      if (
        vueColumns[column].$children[0].field.required !==
        convertToString(newColumns[column].IsRequired)
      ) {
        $(document).ready(function () {
          const titleToMatch = newColumns[column].Title;

          if (!newColumns[column].IsRequired) {
            $(
              '.form-label[title="' + titleToMatch + '"] .required-indicator',
            ).remove();
          }
          if (newColumns[column].IsRequired) {
            const newSpan = $("<span>", {
              class: "required-indicator ml-2 w-10",
              text: "*",
            });

            $('.form-label[title="' + titleToMatch + '"]').append(newSpan);
          }
        });
      }

      vueColumns[column].$children[0].field.required = convertToString(
        newColumns[column].IsRequired ?? "",
      );

      vueColumns[column].$children[0].generateField({
        column: newColumns[column],
      });
    }
  }
};

function convertToString(value) {
  if (typeof value === "boolean" || typeof value === "number") {
    return String(value);
  } else {
    return value;
  }
}
