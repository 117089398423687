<template>
  <r-button
    variant="primary"
    class="w-full"
    :override-classes="'whitespace-pre-line'"
    @click="show = true"
  >
    <r-modal
      v-if="show"
      v-model="show"
      :title="title"
      size="md"
      :show="show"
      @hide="close()"
    >
      <r-alert :variant="messageType" :active="message.length > 0">{{
        message
      }}</r-alert>
      <div>
        <p>{{ translations.ExcelFormDescription }}</p>
        <button
          class="btn btn-primary"
          variant="primary"
          @click="downloadExcelTemplate"
        >
          <i class="fas fa-file-excel"></i>
          {{ translations.ExcelFormTemplateDownload }}
        </button>
        <hr />
        <form class="block mt-0" @submit.prevent="uploadExcel">
          <div id="options" class="min-w-0 p-0 border-0">
            <label
              class="mb-0 pb-[calc(0.375rem+1px)] leading-[1.4] block w-full max-w-full whitespace-normal"
              style="font-size: inherit; color: inherit"
              >{{ translations.ImportFromExcel_ImportOptions }}</label
            >
            <div
              id="options-checkbox-group"
              v-model="options"
              class="block mt-0"
            >
              <div
                v-if="config?.AllowInsert != false"
                class="min-h-[1.4rem] !inline-flex"
              >
                <label class="flex items-center cursor-pointer">
                  <r-checkbox
                    id="newRecords"
                    :value="insert"
                    name="newRecords"
                    class="absolute left-2 -mt-[3px]"
                    @change="handleInput"
                  />
                  <span class="ml-[21px]">
                    {{ translations.ExcelFormOptionNewLines }}
                  </span>
                </label>
              </div>
              <br
                v-if="
                  config?.AllowInsert != false && config?.AllowUpdate != false
                "
              />
              <div
                v-if="config?.AllowUpdate != false"
                class="min-h-[1.4rem] !inline-flex"
              >
                <label class="flex items-center cursor-pointer">
                  <r-checkbox
                    id="existingRecords"
                    :value="update"
                    name="existingRecords"
                    class="absolute left-2 -mt-[18px]"
                    @change="handleChange"
                  />
                  <span class="ml-[21px] translate-up">
                    {{ translations.ExcelFormOptionUpdatedLines }}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div id="options" class="min-w-0 p-0 mb-[1rem] border-0">
            <label
              class="mb-0 pb-[calc(0.375rem+1px)] leading-[1.4] block w-full max-w-full whitespace-normal"
              style="font-size: inherit; color: inherit"
              >{{ translations.ExcelFormFileLabel }}</label
            >
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                @change="handleFilePick"
              />
              <label class="custom-file-label">
                <span class="!block form-file-text">No file chosen</span>
              </label>
            </div>
          </div>
        </form>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <r-button
            variant="secondary"
            :override-classes="'border-solid border-[1px] border-transparent'"
            @click="show = false"
          >
            {{ translations.Close }}
          </r-button>
          <r-button
            type="button"
            variant="primary"
            class="float-right"
            @click="uploadExcel()"
            >{{ translations.ButtonExcelImport }}</r-button
          >
        </div>
      </template>
    </r-modal>
    <div v-if="icon" class="float-left" v-html="icon" />
    {{ title }}
  </r-button>
</template>

<script>
import {importExcelTemplate} from "@/services/excel.js";
import {getExcelImportTemplate} from "@/services/v2/excel/template/getExcelImportTemplate.js";
import {saveBlob} from "@/util/saveBlob.js";
import {getActiveWindow} from "@/functions/window/getActiveWindow.js";
import RButton from "@/components/elements/RButton";
import RAlert from "@/components/elements/RAlert";
import RCheckbox from "@/components/elements/RCheckbox";
import RModal from "@/components/elements/RModal";

export default {
  components: {
    RButton,
    RCheckbox,
    RAlert,
    RModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    fullTableName: {
      type: String,
      required: false,
      default: "",
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    config: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      options: {},
      file: null,
      insert: false,
      update: false,
      message: "",
      messageType: "",
      show: false,
    };
  },
  computed: {
    translations: function () {
      return this.$store.getters.translations;
    },
  },
  methods: {
    handleFilePick(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) this.file = selectedFile;

      const label = event.target.nextElementSibling;
      if (label) {
        label.innerText = selectedFile ? selectedFile.name : "No file chosen";
      }
    },
    close() {
      this.message = "";
      this.messageType = "";
      this.show = false;
    },
    handleInput(value) {
      this.insert = value;
    },
    handleChange(value) {
      this.update = value;
    },
    uploadExcel: async function () {
      this.message = "";
      this.messageType = "info";
      let formData = new FormData();

      formData.append(`file`, this.file);
      try {
        const window = getActiveWindow();

        const result = await importExcelTemplate({
          formData,
          insert: this.insert,
          update: this.update,
          fullTableName: this.fullTableName,
          criteria: this.config?.IncludeCriteria
            ? window.output.Request.Criteria
            : null,
        });

        if (result && result.Error) {
          this.message = result.Error.Message;
          this.messageType = "danger";
        } else {
          this.message = result.Succes?.Message ?? this.translations.Updated;
          global.session.activeWindow.reset();
        }
      } catch (error) {
        console.log({error});
      }
    },
    downloadExcelTemplate: async function () {
      const window = getActiveWindow();

      const result = await getExcelImportTemplate({
        fullTableName: this.fullTableName,
        criteria: this.options?.IncludeCriteria
          ? window.output.Request.Criteria
          : null,
      });

      const fileName = `${window.title()}-template.xlsx`;

      saveBlob({
        blob: result,
        fileName,
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    },
  },
};
</script>

<style>
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.15rem + 2px);
  margin-bottom: 0;
  pointer-events: auto;
}

.custom-file-input {
  position: relative;
  z-index: -5;
  width: 100%;
  height: calc(2.15rem + 2px);
  margin: 0;
  opacity: 0;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 3px;
}

.custom-file-input:focus {
  outline: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.15rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.4;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.15rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.4;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0 0 0;
}

*::after {
  box-sizing: border-box;
}

.translate-up {
  transform: translateY(-7px);
}
</style>
