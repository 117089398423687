<template>
  <div>
    <label class="container readonly relative mb-0 text-[14px] cursor-pointer">
      <input
        v-if="preventDefaultEvent"
        type="checkbox"
        class="!opacity-0 !h-0 !w-0 -z-1"
        :checked="checked"
        @change="handleChange"
        @click.prevent="noop()"
      />
      <input
        v-else
        type="checkbox"
        class="!opacity-0 !h-0 !w-0 -z-1"
        :checked="checked"
        :disabled="isReadOnly"
        @change="handleChange"
      />
      <span
        :class="`!absolute top-0.5 left-2 h-[1rem] w-[1rem] checkboxBorder checkmark ${
          value?.IsReadOnly || isReadOnly ? 'readonly' : ''
        }`"
      ></span>
    </label>
  </div>
</template>

<script>
export default {
  Name: "RCheckbox",
  props: {
    value: {
      type: [Object, Boolean],
      default: null,
      required: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    checked() {
      return this.value?.Value ?? this.value;
    },
    preventDefaultEvent() {
      return this.value?.DisableDefaultCheckoxBehaviour;
    },
  },
  methods: {
    handleChange($event) {
      return this.$emit("change", $event.target.checked);
    },
    noop() {
      // No operation (noop) function, does nothing
    },
  },
};
</script>

<style scoped>
.checkmark {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.container input:checked ~ .checkmark {
  background-color: #f26522;
}

.container input:checked ~ .checkmark.readonly {
  background-color: rgb(223, 224, 226);
}

.container input:focus ~ .checkmark {
  box-shadow: 0px 0px 11px 1px rgba(238, 99, 46, 0.5) !important;
}

.container input:focus ~ .checkmark.readonly {
  box-shadow: none !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 5.5px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkboxBorder {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.container input:checked ~ .checkboxBorder {
  border: none !important;
  border-radius: 2px;
}

.container input:checked ~ .checkmark.readonly {
  background-color: rgb(223, 224, 226);
}

.container input:focus ~ .checkboxBorder {
  border: 1px solid rgba(238, 99, 46, 0.3);
  border-radius: 2px;
}

.container input:focus ~ .checkmark.readonly {
  box-shadow: none !important;
}
</style>
