export const formRowIsDirty = ({originalRow, newRow}) => {
  const exemptedColumns = ["DaysCalculated"];

  const originalValues = originalRow.map((cell) => {
    if (
      typeof cell.Value === "object" &&
      cell.Value !== null &&
      cell.Column.Name === "Label"
    ) {
      return Object.keys(cell.Value)[0];
    }
    return cell.Value;
  });

  const newValues = newRow.map((cell) => {
    const value = cell.NewValue;
    if (
      typeof value === "object" &&
      value !== null &&
      cell.Column.Name === "Label"
    ) {
      return Object.keys(value)[0];
    }
    return value;
  });

  for (const value in originalValues) {
    if (exemptedColumns.includes(originalRow[value].Column.Name)) {
      continue;
    }

    if (originalValues[value] !== newValues[value]) {
      return true;
    }
  }

  return false;
};
