<template>
  <label class="form-field">
    <div class="form-label" title="Artikelnr.">
      {{ label }}:
      <span v-if="required" class="required-indicator" title="">*</span>
    </div>

    <div class="form-input">
      <input
        type="text"
        data-field-index=""
        :required="required"
        name="ItemID"
        placeholder=""
        class="form-control editable-text"
        v-model="content"
        @input="handleInput"
        data-hint=""
        data-step=""
        :maxlength="maxLength"
      />
      <slot></slot>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      required: false,
      type: String,
      default: "",
    },
    maxLength: {
      required: false,
      type: Number,
      default: null,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
      default: function () {
        return "";
      },
    },
  },
  data: function () {
    return {
      content: this.value,
    };
  },
  watch: {
    value: function (val) {
      this.content = val;
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style></style>
