import $ from "jquery";
import Window from "../model/window";
import scroll from "./scrollContainer";
import ComboboxClass from "./combobox.class";
import templater from "../interface/templater.js";
import formatter from "../model/formatter";
import store from "../../state/store";
import Combobox from "./combobox.class";

/**
 * Combobox component class, interface logic
 * @property {*} timer - Timer
 * @property {*} fetchTimer - Fetch timeout timer
 * @property {Session} session - Session reference
 * @property {updateMatch} updateMatch - Update event element should match ".combobox"
 * @property {element} clickTarget - Click target
 */
export default class ComboboxUI {
  static timer = null;
  static fetchTimer = null;
  static session = null;
  static updateMatch = ".combobox";
  static clickTarget = null;
  // static cClass = Combobox

  /**
   * Get the corresponding comboboxclass object for a given combobox element
   * @param {jQuery} el - jQuery element
   * @returns {Combobox} Combobox Class
   */
  static getClass(el) {
    let c = ComboboxClass.pool[el.attr("data-id")];
    if (!c) {
      console.error("no class for", el);
      return null;
    }
    // set el so it can be used
    c.el = el;
    return c;
  }

  /**
   * Get the color white on black that works best with the provided color
   * This is used for making a good contrast on backgrounds
   * @param {string} bgColor Hexadecimal string
   * @returns {string} Either #000 or #fff (Black or White)
   */
  getColorByBgColor(bgColor) {
    if (!bgColor) {
      return "";
    }
    return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
      ? "#000"
      : "#fff";
  }

  /**
   * Open the menu for a combobox
   * @param {Combobox} cb - Combobox
   * @param {*} e - Event
   * @returns {void}
   */
  static open(cb, e = null) {
    if (!cb) {
      return;
    }

    let $el = cb.el;
    if (e) {
      e.stopImmediatePropagation();
    }

    if ($el.hasClass("disabled")) {
      $(".menu").addClass("hide");
      return;
    }

    // clear filter timer(nothing to display)
    if (ComboboxUI.timer) {
      clearTimeout(ComboboxUI.timer);
      ComboboxUI.timer = null;
    }

    // if already open, skip
    if ($el.find(".menu:visible").length) {
      return;
    }

    // hide all menus expect for this one
    $(".menu").addClass("hide");
    $el.find(".menu").removeClass("hide");

    scroll.positionMenu($el);
  }

  /**
   * Change = set value
   * @param {Combobox} cb - Combobox
   * @returns {void}
   */
  static change(cb) {
    if (!cb) return;

    if (cb.specification.freeType) {
      return;
    }

    let $el = cb.el;
    let valuefield = $el.find("input[type=hidden]").val();

    cb.setTextByValue(valuefield);
    ComboboxUI.select(cb, undefined, undefined, false, true);
  }

  /**
   * Select value and close combobox
   * @param {Combobox} cb - Combobox
   * @returns {void}
   */
  static async selectAndClose(cb, value = undefined) {
    ComboboxUI.select(cb, undefined, value, true);
    let eventVal = cb.specification.freeType ? value : cb.value;
    let $el = cb.el;

    $el.find("input[name]").trigger("value-accept", [eventVal]);
    $el.attr("data-suggestion", null);
    if ($el.is(".free")) {
      await ComboboxUI.reset(cb);
      return;
    }
    ComboboxUI.close(cb);
    return;
  }

  /**
   * Close combobox
   * @param {Combobox} cb - Combobox
   * @returns {void}
   */
  static async close(cb, useTimeout = false) {
    if (!cb) return;

    let $el = cb.el;

    // add a small delay when necessary
    if (useTimeout) {
      ComboboxUI.timer = setTimeout(() => ComboboxUI.close(cb, false), 80);
      return;
    }

    // close dropdown
    $el.find(".menu").addClass("hide");
    ComboboxUI.timer = null;

    // special case: open scan-result
    let search = cb.oldText;
    let searchval = cb.oldValue;
    if (cb.specification.tableName === "Core.virtual_Scanbox" && searchval) {
      let window = new Window(ComboboxUI.session);
      window.loading = true;

      try {
        //await window.render()
        window.customTitle = "<i class='fas fa-spinner fa-spin'>";
        window.render();
        window.focus();

        let output = await ComboboxUI.session.request(
          "/Admin/WebServices/CoreWebServices.asmx/OpenNodeByValue",
          {
            value: searchval,
            description: search,
          },
        );

        await window.process(output);
        window.customTitle = null;
        window.loading = false;
        await window.render();
        store.commit("refreshTabs");
        store.commit("updateWindow");
      } catch (err) {
        console.error(err);
        window.dispose();
      }
    }
  }

  static async selectByValue(cb, value, noFilter = false, noTrigger = false) {
    if (!cb) return;

    let $el = cb.el;
    if ($el.hasClass("disabled")) {
      return;
    }

    let $option = $($el.find('.dropdown-option[data-value="' + value + '"]'));

    if ($option?.length === 0 ?? !$option) return;

    let text = $option.text();
    value = $option.attr("data-value");

    ComboboxUI.select(cb, text, value, noFilter, noTrigger);
  }

  /**
   * Select a value referenced by index
   * @param {Combobox} cb - Combobox
   * @param {string} index - Index of the option
   * @param {boolean} noFilter - Should we filter result?
   * @param {boolean} noTrigger - Should jquery events be triggered?
   * @returns {void}
   */
  static async selectByIndex(cb, index, noFilter = false, noTrigger = false) {
    if (!cb) return;

    let $el = cb.el;
    if ($el.hasClass("disabled")) {
      return;
    }

    let $option = $($el.find(".dropdown-option").get(index));
    let text = $option.text();
    let value = $option.attr("data-value");

    ComboboxUI.select(cb, text, value, noFilter, noTrigger);
  }

  /**
   * Select value
   * @param {Combobox} cb - Combobox
   * @param {string} text - Value text
   * @param {*} value - Value
   * @param {boolean} noFilter - Should we filter result?
   * @param {boolean} noTrigger - Should jquery events be triggered?
   * @returns {void}
   */
  static async select(
    cb,
    text = undefined,
    value = undefined,
    noFilter = false,
    noTrigger = false,
    clearDependentComboboxes = true,
  ) {
    if (!cb) return;

    let $el = cb.el;
    if ($el.hasClass("disabled")) {
      return;
    }

    let textVal = $el.find("input.combobox-input").val();
    // define search text and value
    let search =
      typeof text === "string"
        ? text
        : $el.find(".selected.dropdown-option").first().text() ||
          cb.oldText ||
          cb.startingText ||
          "";

    let searchval =
      typeof value === "string" || value === null
        ? value || ""
        : $el.find(".selected.dropdown-option").first().attr("data-value") ||
          cb.oldValue ||
          cb.startingValue ||
          "";

    if (
      typeof text === "undefined" &&
      typeof value === "undefined" &&
      cb.specification.nullable &&
      textVal == "" &&
      !$el.find(".selected.dropdown-option").length
    ) {
      search = searchval = null;
    } else if (
      !cb.specification.freeType &&
      !cb.optionExist(search, searchval)
    ) {
      search = cb.oldText || cb.startingText || "";
      searchval = cb.oldValue || cb.startingValue || null;
    }

    // save old value
    cb.oldText = search;
    cb.oldValue = searchval;

    if (value && text) {
      if (Object.prototype.hasOwnProperty.call(cb.oldValueList, cb.oldValue)) {
        delete cb.oldValueList[cb.oldValue];
      } else {
        cb.oldValueList[cb.oldValue] = {description: cb.oldText.trim()};
      }
    }

    if (cb.specification.type === "multi-selector") {
      // Check input[checkbox] in list
      $el
        .find("[data-value='" + cb.oldValue + "']")
        .find("input[type=checkbox]")
        .prop(
          "checked",
          Object.prototype.hasOwnProperty.call(cb.oldValueList, cb.oldValue),
        );

      // set selected value
      $el
        .find("input.combobox-input")
        .val(formatter.joinDescription(cb.oldValueList));
      $el.find("input[type=hidden]").val(formatter.joinKeys(cb.oldValueList));
    } else {
      // set selected value
      $el.find("input.combobox-input").val(search?.trim() ?? "");
      $el.find("input[type=hidden]").val(searchval);
    }

    // trigger change
    if (!noTrigger) {
      $el.find("input[type=hidden]").trigger("change", [true]);
    }

    // special case: update reference variable
    let $reference = $el.find("[data-window-event]");
    if ($reference.length) {
      let oldval = $reference.attr("data-window-event");
      let x = oldval.split(":").slice(0, -1).concat(searchval);
      $reference.attr("data-window-event", x.join(":"));
    }

    // reset suggestion
    $el.attr("data-suggestion", null);

    // Filter when requested
    if (!noFilter) {
      ComboboxUI.filter(cb, Boolean(text));
    }

    if (clearDependentComboboxes) {
      // Clear dependent comboboxes
      ComboboxUI.clearDependentCombobox(cb);
    }

    return searchval;
  }

  /**
   * Clear all comboboxes where the current colum-name is used as extra key
   * @param {Combobox} cb - Combobox
   * @returns {void}
   */
  static async clearDependentCombobox(cb) {
    let window = ComboboxUI.session.getClosestWindow(cb.el);
    if (!window) return;
    let comboboxes = $(window.element).find(".dropdown.combobox");
    let combobox = null;
    let column = null;
    let columnName = null;

    if (cb && cb.cell) {
      columnName = cb.cell.Column.Name;
    } else {
      columnName = cb.specification.columnName;
    }

    for (let el of comboboxes) {
      combobox = ComboboxUI.getClass($(el));
      if (combobox && combobox.cell) {
        column = window.output.Data.Columns[combobox.cell.Column.Name];
      } else {
        column = window.output.Data.Columns[combobox.specification.columnName];
      }

      if (column && column.Dropdown && column.Dropdown.ExtraKeys) {
        for (let extraKey of column.Dropdown.ExtraKeys) {
          // Avoid infinite loop when columnName on dropdown does not equal the column.Name but the column.Name exists in the ExtraKeys
          if (column.Name === extraKey) continue;
          if (extraKey === columnName) {
            const {items} = await ComboboxUI.fetch(combobox);

            if (items.some((item) => item.Text === combobox.text)) continue;

            await ComboboxUI.reset(combobox);
          }
        }
      }
    }
  }

  /**
   * Highlight text in option
   * @param {Jquery} el - Jquery element
   * @param {string} query - Text search query
   */
  static highlight($el, query = null) {
    if (!$el) {
      return;
    }
    if (!query) {
      $el.find("b").contents().unwrap();
      return;
    }
    $el.find("b").contents().unwrap();
    // Get existing text
    let text = $el.text();

    // Replace characters in text with the character including a B tag to make it look fat
    text = text.replace(
      new RegExp(
        "(" +
          query.replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, "\\$1") +
          ")",
        "gi",
      ),
      "<b>$1</b>",
    );

    // Replace the contents of the text element with the text including the b tags set previously
    text = $el
      .html()
      .replace(/<text>[\s\S]*?<\/text>/, "<text>" + text + "</text>");

    // Set the new text
    $el.html(text);
  }

  /**
   * Filter results
   * @param {Combobox} cb - Combobox
   * @param {boolean} soft - Soft filter
   * @param {boolean} unHighlight - Should highlights be removed?
   * @todo What is soft filter??
   * @returns {void}
   */
  static async filter(cb, soft = false, unHighlight = false) {
    if (!cb) return;
    let $el = cb.el;

    const value = $el.find("input.combobox-input").val();

    // Check if the value is unchanged and if so show the entire result list in the menu
    let valueUnchanged = false;
    if (cb.oldText && value)
      valueUnchanged =
        cb.oldText.trim().toLowerCase() === value.trim().toLowerCase();

    if ($el.hasClass("disabled") || cb.specification.type == "multi-selector") {
      return;
    }

    // Make sure the combobox is open
    ComboboxUI.open(cb, null);

    // Get current input text
    let query = ($el.find("input.combobox-input").val() || "")
      .trim()
      .toLowerCase();

    // let changed = String(this.query).trim().toLowerCase() !== query
    // this.query = query
    let $opts = $el.find(".menu .dropdown-option");
    let count = $opts.length;
    $opts.each(function () {
      // Hide no-result options and highlight if necessary
      let $this = $(this);

      if ($this.hasClass("exclude")) {
        return;
      }

      // get option text
      let optionText = String($this.text()).trim().toLowerCase();
      let alternativeTagText = "";
      if ($this.data("attr-tags")) {
        const tags = $this.data("attr-tags");
        if (!isNaN(tags)) {
          alternativeTagText = tags;
        } else {
          tags.trim().toLowerCase();
        }
      }

      $this.toggleClass("selected", optionText === query);
      if (!valueUnchanged) {
        if (!soft) {
          let show =
            optionText.indexOf(query) !== -1 ||
            alternativeTagText?.indexOf(query) !== -1;
          if (cb.specification.type == "treeview") {
            let $x = $this;
            if ($x.parent().hasClass("branch-name")) {
              $x = $x.closest(".branch");
            }
            $x.toggleClass("hide", !show);
          } else {
            $this.toggleClass("hide", !show);
          }

          if (show) {
            ComboboxUI.highlight($this, unHighlight ? null : query);
          }
        } else if (unHighlight) {
          ComboboxUI.highlight($this, null);
        }
      } else {
        $this.toggleClass("hide", false);
        ComboboxUI.highlight($this, null);
      }

      if (!--count) {
        $el
          .find(".no-items")
          .toggleClass(
            "hide",
            $el.find(".dropdown-option.hide").length !== $opts.length,
          );
      }
    });

    if (cb.specification.type == "treeview") {
      if (query) {
        $el
          .find(".stick, .branch")
          .not(".hide")
          .each(function () {
            $(this)
              .parents(".branch")
              .removeClass("hide")
              .removeClass("collapsed");
          });
      }
    }
    ComboboxUI.showSuggestion(cb);
    scroll.positionMenu($el);
  }

  /**
   * Show suggestion text
   * @param {Combobox} cb - Combobox
   * @returns {void}
   */
  static showSuggestion(cb) {
    if (!cb) return;

    let $el = cb.el;
    let $openOptions = $el.find(".menu .dropdown-option:visible");
    let val = $el.find("input.combobox-input").val();
    if (val && !$el.is(".free")) {
      if (!cb.specification.freeType && $openOptions.length === 1) {
        $openOptions.first().addClass("selected");
      }

      let str = $openOptions.first().text() || null;
      if (str) {
        str =
          val.toLowerCase() === str.substring(0, val.length).toLowerCase()
            ? val + str.substring(val.length, str.length)
            : null;
      }
      $el.attr("data-suggestion", str);
    } else {
      $el.attr("data-suggestion", null);
    }
  }

  /**
   * Fetch new items
   * @param {Combobox} cb - Combobox
   * @param {string} val - Fetch value
   * @param {boolean} fetchMore - Should items be appended?
   * @returns {Promise}
   */
  static async fetch(cb, val = "", fetchMore = false) {
    if (!cb || cb.specification.type == "treeview") {
      return; // Do not re-fetch data for treeview's otherwise opening branches is not working
    }

    let window = ComboboxUI.session.getClosestWindow(cb.el);
    let subject = null;
    let prefix = null;
    let column = null;

    let primaryKeys = cb.specification.filter || {};

    let extraValues = {};

    if (window) {
      subject = window.output.Request.Subject;
      prefix = window.output.Request.Prefix;

      try {
        if (cb && cb.cell) {
          column = window.output.Data.Columns[cb.cell.Column.Name];
        } else {
          column = window.output.Data.Columns[cb.specification.columnName];
        }

        if (
          column != null &&
          column.Dropdown != null &&
          column.Dropdown.ExtraKeys != null
        ) {
          for (let primaryKey of column.Dropdown.ExtraKeys) {
            // First check if function getRow exists (used by quickscan and rentOrderItem, can be added to all windows?)
            if (window.getRow !== undefined) {
              let $row = $(cb.el).closest(".table-row-group");

              primaryKeys[primaryKey] = window.getRow($row.get(0), window)[
                primaryKey
              ];

              if (
                primaryKeys[primaryKey] == null &&
                window.output.Request.Criteria.length > 0
              ) {
                primaryKeys[primaryKey] =
                  window.output.Request.Criteria[0][primaryKey];
              }
            } else if (
              window.output.Request.Subject == "Rental.virtual_Return"
            ) {
              let rowIndex = $(cb.el).parents(".table-row").attr("data-rowid");
              primaryKeys[primaryKey] =
                window.customData.rows[rowIndex].old[primaryKey];
            } else if (
              window.output.Data != null &&
              window.output.Data.Rows != null &&
              window.output.Data.Rows[0] != null &&
              cb.cell != null
            ) {
              let rowIndex = 0;

              if (
                window.output.Request.Subject == "Rental.virtual_PickListItem"
              ) {
                rowIndex = $(cb.el)
                  .parents(".table-row")
                  .attr("data-row-index");
              }

              let primaryKeyCell = window.output.FullTable.Rows[
                rowIndex
              ].filter((x) => x.Column.Name == primaryKey);
              if (primaryKeyCell.length > 0) {
                primaryKeyCell = primaryKeyCell[0];

                // Split on spaces and lowerlines for foreignkey-columns
                let primaryKeyColumn = primaryKey;

                const cellElement = $(window.element).find(
                  `[vue-input][name="${primaryKeyCell.Column.Name}"]`,
                );
                if (cellElement.length > 0) {
                  primaryKeys[primaryKeyColumn] = cellElement.val();
                  continue;
                }

                if (
                  column.Dropdown.TableName != "Rental.ItemGroupFieldPopulate"
                ) {
                  primaryKeyColumn =
                    primaryKeyColumn.split(" ")[
                      primaryKeyColumn.split(" ").length - 1
                    ];
                  primaryKeyColumn =
                    primaryKeyColumn.split("_")[
                      primaryKeyColumn.split("_").length - 1
                    ];
                }

                primaryKeys[primaryKeyColumn] = primaryKeyCell.IsDirty
                  ? primaryKeyCell.NewValue
                  : primaryKeyCell.Value;
              }
            }
          }
        }
      } catch (ex) {
        console.log({ex});
      }

      // check if this works to add CustomerID on rentform, so we can filter the Dimension dropdowns
      if (cb.specification.columnName.startsWith("DimensionID-")) {
        if (window.customData != null && window.customData["CustomerID"]) {
          primaryKeys["CustomerID"] = window.customData["CustomerID"];
        } else if ($(cb.el).parents(".table-row").length > 0) {
          let rowindex = $(cb.el).parents(".table-row").data("row-index");

          if (
            window.customData.rows &&
            window.customData.rows.length > rowindex &&
            window.customData.rows[rowindex].old.CustomerID
          ) {
            primaryKeys["CustomerID"] =
              window.customData.rows[rowindex].old.CustomerID;
          }
        }
      }
    }

    if (column?.Dropdown?.ExtraCriteria) {
      for (let criteria of column.Dropdown.ExtraCriteria) {
        if (criteria.SendRowValue) {
          primaryKeys[criteria.PrimaryKeyName] =
            $(window.element)
              .find(`[name='${criteria.FormColumnName}']`)
              .val() ??
            null;
        } else {
          primaryKeys[criteria.PrimaryKeyName] = criteria.Value;
        }
      }
    }

    if (
      cb.el.parents(".vue-generic-modal").length ||
      cb.el.parents(".view-return").length ||
      cb.el.parents(".view-quickrent").length
    ) {
      const additionalExtraKeys = this.getPrimaryKeyValuesFromModal({
        element: cb.el,
        extraKeys: cb.specification.extraKeys,
        passthroughValues: cb.specification.passthroughValues,
      });

      primaryKeys = {
        ...primaryKeys,
        ...additionalExtraKeys,
      };
    }

    cb.el.addClass("fetching");

    let offset = fetchMore ? cb.el.find(".dropdown-option").length : null;
    let rowCount = fetchMore ? cb.specification.rowCount : null;
    let items = await ComboboxUI.session.combobox(
      cb.specification.tableName,
      cb.specification.columnName,
      primaryKeys,
      val,
      extraValues,
      null,
      offset,
      rowCount,
      subject,
      prefix,
    );
    cb.el.removeClass("fetching");
    // ParentProperty is 'Parent' + TableName + 'ID' (f.e. ParentCategoryID)
    let parentColumn =
      "Parent" + cb.specification.tableName.split(".")[1] + "ID";
    for (let item of items) {
      if (item.Attributes && parentColumn in item.Attributes) {
        cb.specification.treeviewParentProperty = parentColumn;
        cb.specification.type = "treeview";

        let $menu = cb.el.find(".menu");
        $menu.addClass("tree-view combo-tree");
        break;
      }
    }

    ComboboxUI.setItems(cb, items, val, fetchMore);
    ComboboxUI.showSuggestion(cb);
    return {items};
  }

  static getPrimaryKeyValuesFromModal({element, extraKeys, passthroughValues}) {
    const $fieldsElement =
      $(element).parents(".modal-body")[0] ?? $(element.parents(".row")[0]);
    const $fieldElements = $($fieldsElement).find(".form-input");
    const $inputFieldElements = $($fieldElements).find("[name]");

    const values = {};
    // Get value from each field in $inputFieldElements and map it to name property value
    $inputFieldElements.each((index, element) => {
      const $element = $(element);
      const name = $element.attr("name");
      const value = $element.val();
      values[name] = value;
    });

    const extraValues = {...passthroughValues};

    for (let key of extraKeys) {
      if (key in values) {
        extraValues[key] = values[key];
      }
    }

    if (extraKeys.includes("WarehouseID")) {
      extraValues["WarehouseID"] = store.state.activeWarehouse;
    }

    return extraValues;
  }

  /**
   * Reset combobox
   * @param {Combobox} cb - Combobox
   * @param {boolean} tryRefetch - Should we try to refetch?
   * @returns {Promise}
   */
  static async reset(cb) {
    if (!cb) return;

    let $el = cb.el;
    let oldText = cb.startingText || "";
    let oldval = cb.startingValue || null;

    cb.setInitialValues(oldText, oldval);

    // set back to starting values
    ComboboxUI.select(cb, oldText, oldval, false, false, false);
    ComboboxUI.close(cb);
    // remove selection
    $el
      .find(".dropdown-option")
      .not(".exclude")
      .removeClass("selected")
      .removeClass("hide");

    if (cb.specification.tableName) {
      let val = $el.find("input.combobox-input").val();
      await ComboboxUI.fetch(cb, val);
      ComboboxUI.filter(cb);
    }

    $el.find(".menu .dropdown-option").each(function () {
      ComboboxUI.highlight($(this));
    });

    ComboboxUI.close(cb);

    $el.blur();
  }

  /**
   * Set items list
   * @param {Combobox} cb - Combobox
   * @param {Array} items - Array of items {Text, Value, Attributes}
   * @param {string} val - Value to highlight
   * @param {boolean} append - Should items be appended?
   * @returns {void}
   */
  static setItems(cb, items, val, append) {
    if (!cb) return;

    let $el = cb.el;
    let $menu = $el.find(".menu");
    if (!append) {
      cb.clearItems();
    }

    cb.populate(items);
    let $comboboxSelection = templater.refreshComponent("comboboxSelection", {
      session: this.session,
      combobox: cb,
    });

    $menu.html($comboboxSelection);
  }

  /**
   * Create event listeners, initialise component
   * @param {document} doc - Page document
   */
  static init(doc) {
    // let $selected = $(el).find(".dropdown-option.selected")
    // if($selected.length) {
    // 	$(el).find("input").val($selected.text())
    // }

    $(doc).on("mousedown", function (e) {
      ComboboxUI.clickTarget = e.target;
    });

    $(doc).on("click", ".combobox .menu .dropdown-option", function (e) {
      // if e.target has disabled attribute return
      if ($(e.target).attr("disabled")) return;
      let $el = $(this).closest(".combobox");
      let cb = ComboboxUI.getClass($el);

      if (e.which !== 1 || cb.specification.type === "multi-selector") {
        return;
      }

      $el.find(".dropdown-option").removeClass("selected");
      $(this).addClass("selected");

      e.stopImmediatePropagation();
      ComboboxUI.selectAndClose(cb);
      return false;
    });

    $(doc).on("click", ".combobox .menu .dropdown-option text", function (e) {
      let $el = $(this).closest(".combobox");
      let cb = ComboboxUI.getClass($el);

      if (e.which !== 1 || cb.specification.type === "multi-selector") {
        return;
      }

      $el.find(".dropdown-option").removeClass("selected");
      $(this).parent().addClass("selected");

      e.stopImmediatePropagation();
      ComboboxUI.selectAndClose(cb);
      return false;
    });

    $(doc).on("click", ".combobox input.combobox-input", async function (e) {
      if (e.which !== 1) {
        return;
      }
      let el = $(this).closest(".combobox");
      let cb = ComboboxUI.getClass(el);
      ComboboxUI.open(cb, e);
    });

    $(doc).on("focus", ".combobox input.combobox-input", async function (e) {
      let el = $(this).closest(".combobox");
      let cb = ComboboxUI.getClass(el);
      let val = el.find("input.combobox-input").val();
      $(this).select();
      if (
        !cb.specification.lazyLoading &&
        cb.specification.tableName &&
        (val === (cb.oldText || "") || val === (cb.startingText || ""))
      ) {
        await ComboboxUI.fetch(cb, "");
      }

      ComboboxUI.open(cb, e);
    });

    $(doc).on("change", ".combobox input[type=hidden]", function (e, arg) {
      let cb = ComboboxUI.getClass($(this).closest(".combobox"));
      if (!arg) ComboboxUI.change(cb);
    });

    $(doc).on(
      "click",
      ".dropdown.combobox.multi-selector .dropdown-option",
      async function (e) {
        e.stopImmediatePropagation();

        let el = $(this).closest(".combobox");
        let cb = ComboboxUI.getClass(el);
        let outsideTarget = e.relatedTarget || ComboboxUI.clickTarget;
        let selectedOption = $(outsideTarget).closest(
          ".option.dropdown-option",
        );

        if (selectedOption) {
          ComboboxUI.select(
            cb,
            selectedOption.text(),
            selectedOption.attr("data-value"),
            true,
          );
        }
      },
    );

    $(doc).on(
      "focus",
      ".dropdown.combobox input[type=checkbox]",
      async function () {
        // On click of checkbox, make sure menu stays open
        var menu = $(event.target).parents(".menu");
        menu.removeClass("hide");
      },
    );

    $(doc).on("blur", ".combobox input.combobox-input", async function (e) {
      let el = $(this).closest(".combobox");
      let cb = ComboboxUI.getClass(el);
      let isFree = cb.specification.freeType;
      let val = el.find("input.combobox-input").val();
      let outsideTarget = e.relatedTarget || ComboboxUI.clickTarget;
      let selectedOption = $(outsideTarget).parents(".option.dropdown-option");

      if (
        outsideTarget &&
        el.has($(outsideTarget)).length &&
        !selectedOption.length
      ) {
        return;
      }

      if (selectedOption && cb.specification.type === "multi-selector") {
        return;
      } else if (!isFree && val !== cb.oldText && !cb.specification.nullable) {
        ComboboxUI.select(cb, cb.oldText, cb.oldValue, true);
      } else if (!isFree) {
        ComboboxUI.select(cb);
      }
      el.find("input[type=hidden]").trigger("blur");
    });

    $(doc).on("click", ".combobox .show-more", async function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      let el = $(this).closest(".combobox");
      let cb = ComboboxUI.getClass(el);
      let val = el.find("input.combobox-input").val();
      ComboboxUI.fetch(cb, val === cb.oldText ? "" : val, true);
    });

    $(doc).on("click", "[data-toggle-combobox]", async function toggleMenu(e) {
      let el = $(this).closest(".combobox");
      let cb = ComboboxUI.getClass(el);
      if (e.which !== 1) {
        return;
      }
      e.stopImmediatePropagation();
      let toggleval = $(".menu", el).hasClass("hide");
      $(".menu").addClass("hide");
      if (toggleval) {
        let val = el.find("input.combobox-input").val();
        if (
          cb.specification.tableName &&
          (val === (cb.oldValue || "") || val === (cb.startingValue || ""))
        ) {
          await ComboboxUI.fetch(cb, "");
        }
        ComboboxUI.open(cb);
      } else {
        ComboboxUI.close(cb);
      }
    });

    $(doc).on(
      "keyup input",
      ".combobox input.combobox-input",
      async function (e) {
        let $this = $(this);
        let $el = $(this).closest(".combobox");
        let cb = ComboboxUI.getClass($el);
        let val = $this.val();

        if (e.which === 9) {
          // Tab key
          return;
        }
        // Reset
        if (e.which === 27) {
          // Esc key
          e.preventDefault();
          e.stopImmediatePropagation();
          await ComboboxUI.reset(cb);
          // ComboboxUI.close(cb)
          return;
        }

        // Accept
        if (e.which === 13) {
          // Enter key
          e.preventDefault();
          e.stopImmediatePropagation();

          // after enter, wait for the filter function, otherwise no item will be selected. This can occur if a handscanner is used in a dropdown,
          // which emulates a keyboard input + enter
          await ComboboxUI.filter(cb);

          let opts = $el.find(".dropdown-option:visible");
          let sel = $el.find(".dropdown-option.selected");
          if (sel.length > 0) {
            val = sel.first().attr("data-value");
          }

          if (!cb.specification.freeType && opts.length === 1 && val) {
            opts.first().addClass("selected");
          }

          ComboboxUI.selectAndClose(cb, val);
          $el.blur();
          return;
        }

        let up = e.which === 38;
        let down = e.which === 40;

        if (up || down) {
          e.preventDefault();

          ComboboxUI.open(cb, null);
          let $options = $el
            .find(".menu .dropdown-option:visible")
            .not(".hide, .exclude");
          let $current = $options.filter(".selected");
          let index = $current.length ? $options.index($current) : -1;

          // if($(el).attr("data-fetch") && (val === $(el).attr("data-old") || val === $(el).attr("data-default"))) {
          //	await ComboboxUI.fetch(cb,"")
          // }

          let $next = null;
          let newIndex =
            index === -1 ? (up ? -1 : 0) : Number(index) + (up ? -1 : 1);
          newIndex = newIndex === $options.length ? 0 : newIndex;
          $next = $options.eq(newIndex);

          if (!$next.length) {
            return;
          }

          ComboboxUI.select(
            cb,
            $next.text(),
            $next.attr("data-value"),
            true,
            true,
          );
          $el.find(".menu .dropdown-option.selected").removeClass("selected");
          $next.addClass("selected");

          //Scroll to item
          let $menu = $el.find(".menu");
          let top = $next.position().top;
          let $parentBranches = $next.parents(".branch");
          $parentBranches.toggleClass("collapsed", false);
          $parentBranches.each(function () {
            top += $(this).position().top;
          });
          $menu.scrollTop(top + $menu.scrollTop());

          return;
        }

        if (ComboboxUI.fetchTimer) {
          clearTimeout(ComboboxUI.fetchTimer);
          ComboboxUI.fetchTimer = null;
        }

        $el.attr("data-suggestion", null);
        ComboboxUI.fetchTimer = setTimeout(async () => {
          if (cb.specification.lazyLoading) {
            let val = $el.find("input.combobox-input").val();
            await ComboboxUI.fetch(cb, val);
          }

          ComboboxUI.filter(cb);
        }, 80);
      },
    );
  }
}
