import {api} from "./api";

export async function sendMail({
  subject,
  recipients,
  reportTemplate,
  body,
  emailTemplate,
  fullTableName,
  primaryKey,
  primaryKeys,
  optionalRecipients,
  optionalHiddenRecipients,
  languageId,
} = {}) {
  const result = await api.post(`/v2/mail/send`, {
    subject,
    recipients,
    reportTemplate,
    body,
    emailTemplate,
    fullTableName,
    primaryKey,
    primaryKeys,
    optionalRecipients,
    optionalHiddenRecipients,
    languageID: languageId.Value,
  });

  return result.data;
}
