
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("../content/form/inputValue.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
            const __nunjucks_template_import__global_generateID = require("../../src/util/generateRandomID.js");
            __nunjucks_module_dependencies__.globals['generateID'] = {
                module: __nunjucks_template_import__global_generateID && __nunjucks_template_import__global_generateID.default || __nunjucks_template_import__global_generateID
            };
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/vueFormField.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form/inputValue.html", false, "views/elements/vueFormField.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("inputValue", t_1);
var macro_t_4 = runtime.makeMacro(
["value", "name", "index", "readOnly", "postback", "editor", "window", "column"], 
[], 
function (l_value, l_name, l_index, l_readOnly, l_postback, l_editor, l_window, l_column, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("value", l_value);
frame.set("name", l_name);
frame.set("index", l_index);
frame.set("readOnly", l_readOnly);
frame.set("postback", l_postback);
frame.set("editor", l_editor);
frame.set("window", l_window);
frame.set("column", l_column);
var t_5 = "";var t_6;
t_6 = (lineno = 3, colno = 30, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "generateID"), "generateID", context, [7]));
frame.set("randomId", t_6, true);
if(frame.topLevel) {
context.setVariable("randomId", t_6);
}
if(frame.topLevel) {
context.addExport("randomId", t_6);
}
t_5 += "\n  ";
var t_7;
t_7 = "";
frame.set("fieldValue", t_7, true);
if(frame.topLevel) {
context.setVariable("fieldValue", t_7);
}
if(frame.topLevel) {
context.addExport("fieldValue", t_7);
}
t_5 += "\n\n  ";
if(l_value) {
t_5 += "\n    ";
var t_8;
t_8 = l_value;
frame.set("fieldValue", t_8, true);
if(frame.topLevel) {
context.setVariable("fieldValue", t_8);
}
if(frame.topLevel) {
context.addExport("fieldValue", t_8);
}
t_5 += "\n  ";
;
}
else {
t_5 += "\n    ";
var t_9;
t_9 = (lineno = 9, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "inputValue")),"setValue"), "inputValue[\"setValue\"]", context, [l_column,l_value,runtime.memberLookup((l_column),"Type")]));
frame.set("fieldValue", t_9, true);
if(frame.topLevel) {
context.setVariable("fieldValue", t_9);
}
if(frame.topLevel) {
context.addExport("fieldValue", t_9);
}
t_5 += "\n  ";
;
}
t_5 += "\n\n\n<div\n    editor=\"";
t_5 += runtime.suppressValue(l_editor, env.opts.autoescape);
t_5 += "\"\n    id=\"";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "randomId"), env.opts.autoescape);
t_5 += "\"\n    value=\"";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "fieldValue"), env.opts.autoescape);
t_5 += "\"\n    index=\"";
t_5 += runtime.suppressValue(l_index, env.opts.autoescape);
t_5 += "\"\n    name=\"";
t_5 += runtime.suppressValue(l_name, env.opts.autoescape);
t_5 += "\"\n    read-only=\"";
t_5 += runtime.suppressValue(l_readOnly, env.opts.autoescape);
t_5 += "\"\n    required=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_column),"IsRequired"), env.opts.autoescape);
t_5 += "\"\n    show-time=\"";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "showTime"), env.opts.autoescape);
t_5 += "\"\n    postback=\"";
t_5 += runtime.suppressValue(l_postback, env.opts.autoescape);
t_5 += "\"\n    placeholder=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_column),"Placeholder"), env.opts.autoescape);
t_5 += "\"\n    maxlength=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_column),"Length"), env.opts.autoescape);
t_5 += "\"\n    min=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_column),"MinNumber"), env.opts.autoescape);
t_5 += "\"\n    max=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_column),"MaxNumber"), env.opts.autoescape);
t_5 += "\"\n    data-hint=\"";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_column),"FieldDescription")), env.opts.autoescape);
t_5 += "\"\n    data-step=\"";
t_5 += runtime.suppressValue(l_index, env.opts.autoescape);
t_5 += "\"\n    windowId=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_window),"id"), env.opts.autoescape);
t_5 += "\"\n    vue-form-field\n></div>";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("generateVueFormField");
context.setVariable("generateVueFormField", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/vueFormField.html", ctx);
          }
        
          return nunjucks.render("views/elements/vueFormField.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/vueFormField.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        