import dayjs from "dayjs";

export default class DaysCalculatedListener {
  daysCalculated;

  daysCalculatedListener;
  dateTimeExpectedStartListener;
  dateTimeExpectedEndListener;

  windowId;
  formElement;

  start(eventWindow) {
    this.windowId = eventWindow.id;
    this.formElement = $(eventWindow.element).find("form");

    if (!this.getFullTableCell("DaysCalculated")) {
      return;
    }

    this.daysCalculatedListener = $(this.formElement).on(
      "change",
      '[name="DaysCalculated"]',
      () => this.process("DaysCalculated"),
    );

    this.dateTimeExpectedStartListener = $(this.formElement).on(
      "change",
      '[name="DateTimeExpectedStart"]',
      () => this.process("DateTimeExpectedStart"),
    );

    this.dateTimeExpectedEndListener = $(this.formElement).on(
      "change",
      '[name="DateTimeExpectedEnd"]',
      () => this.process("DateTimeExpectedEnd"),
    );

    this.process("DateTimeExpectedEnd");
  }

  stop() {
    this.daysCalculatedListener?.off();
    this.dateTimeExpectedStartListener?.off();
    this.dateTimeExpectedEndListener?.off();
  }

  process(fieldName) {
    const startDate = this.getFullTableCell("DateTimeExpectedStart").NewValue;
    const endDate = this.getFullTableCell("DateTimeExpectedEnd").NewValue;
    let daysCalculated = $(this.formElement)
      .find('[name="DaysCalculated"]')
      .val();

    switch (fieldName) {
      case "DateTimeExpectedStart":
        if (daysCalculated) {
          this.setDateFieldValue(
            "DateTimeExpectedEnd",
            dayjs(startDate, "DD-MM-YYYY").add(daysCalculated - 1, "day"),
          );
        }
        break;
      case "DateTimeExpectedEnd":
        if (startDate && endDate) {
          const newDaysCalculated =
            dayjs(endDate, "DD-MM-YYYY").diff(
              dayjs(startDate, "DD-MM-YYYY"),
              "day",
            ) + 1;

          if (
            daysCalculated != newDaysCalculated ||
            newDaysCalculated != this.daysCalculated
          ) {
            daysCalculated = newDaysCalculated;

            this.setDaysCalculated(daysCalculated);
          }
        }
        break;
      case "DaysCalculated":
        if (startDate && daysCalculated > 0) {
          this.setDaysCalculated(daysCalculated);

          let parsedDate;
          if (dayjs(startDate, "YYYY-MM-DDTHH:mm:ss", true).isValid()) {
            parsedDate = dayjs(startDate);
          } else if (dayjs(startDate, "DD-MM-YYYY", true).isValid()) {
            parsedDate = dayjs(startDate, "DD-MM-YYYY");
          } else {
            throw new Error("Invalid date format");
          }

          this.setDateFieldValue(
            "DateTimeExpectedEnd",
            parsedDate.add(daysCalculated - 1, "day"),
          );
        }
        break;
    }

    this.daysCalculated = daysCalculated;
  }

  setDateFieldValue(fieldName, newValue) {
    this.getFullTableCell(fieldName).NewValue = newValue;

    this.getCurrentWindow().vueColumns[fieldName]?.$children[0].updateStartDate(
      newValue,
    );
  }

  setDaysCalculated(newValue) {
    this.getFullTableCell("DaysCalculated").NewValue = newValue;

    $(this.formElement).find('[name="DaysCalculated"]').val(newValue);
  }

  getFullTableCell(fieldName) {
    return (
      this.getCurrentWindow()?.output?.Sub ?? this.getCurrentWindow()?.output
    )?.FullTable?.Rows[0]?.find((x) => x.Column.Name == fieldName);
  }

  getCurrentWindow() {
    return window.session.windows[this.windowId];
  }
}
