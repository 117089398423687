
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
        const __nunjucks_template_import__dep_1 = require("./table.html");
        
        const __nunjucks_template_import__dep_2 = require("./form.html");
        
        const __nunjucks_template_import__dep_3 = require("./form/inputField.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/quickrent.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/quickrent.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n";
env.getTemplate("views/content/table.html", false, "views/content/quickrent.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("table", t_4);
output += "\n";
env.getTemplate("views/content/form.html", false, "views/content/quickrent.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("form", t_7);
output += "\n";
env.getTemplate("views/content/form/inputField.html", false, "views/content/quickrent.html", false, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
t_10.getExported(function(t_12,t_10) {
if(t_12) { cb(t_12); return; }
context.setVariable("inputField", t_10);
output += "\n\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true) {
output += "\n<div class=\"content row\" style=\"\n\t\t\tmargin-left: 0px;\n\t\t\talign-items: baseline;\n\t\">\n  <form class=\"form-view grid row col-12 view-quickrent\">\n    ";
;
}
else {
output += "\n    <div class=\"content\">\n      <form class=\"form-view grid row view-quickrent\">\n        ";
;
}
output += "\n\n        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"Subject") == "Rental.virtual_QuickRent" && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"allSettings")),"ShowScanAmountField") == true) {
output += "\n        <div class=\"col col-xs-12 col-sm-6 col-md-3 order-scanbox\">\n          <label class=\"form-field\">\n            <div class=\"form-label form-label-select\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Amount"), env.opts.autoescape);
output += ":</div>\n            <div class=\"form-input\">\n              <input type=\"number\" name=\"Amount\" value=\"1\" class=\"form-text\">\n            </div>\n          </label>\n        </div>\n        ";
;
}
output += "\n\n        <input type=\"hidden\" name=\"RentalType\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"formType"), env.opts.autoescape);
output += "\">\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"isNewObject")) {
output += "\n\n        <div class=\"col col-xs-12 col-sm-6 col-md-3\">\n          <label class=\"form-field\">\n            <div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Customer"), env.opts.autoescape);
output += ": <span class=\"required-indicator\" title=\"\">*</span></div>\n            <div class=\"form-input required-field\">\n              ";
output += runtime.suppressValue((lineno = 34, colno = 40, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"CustomerID")])), env.opts.autoescape);
output += "\n            </div>\n          </label>\n        </div>\n\n\n        ";
frame = frame.push();
var t_15 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"QuickRentColumns");
if(t_15) {t_15 = runtime.fromIterator(t_15);
var t_14 = t_15.length;
for(var t_13=0; t_13 < t_15.length; t_13++) {
var t_16 = t_15[t_13];
frame.set("col", t_16);
frame.set("loop.index", t_13 + 1);
frame.set("loop.index0", t_13);
frame.set("loop.revindex", t_14 - t_13);
frame.set("loop.revindex0", t_14 - t_13 - 1);
frame.set("loop.first", t_13 === 0);
frame.set("loop.last", t_13 === t_14 - 1);
frame.set("loop.length", t_14);
output += "\n        ";
var t_17;
t_17 = runtime.memberLookup((t_16),"Value");
frame.set("value", t_17, true);
if(frame.topLevel) {
context.setVariable("value", t_17);
}
if(frame.topLevel) {
context.addExport("value", t_17);
}
output += "\n\n        ";
if(runtime.memberLookup((t_16),"Name") == "DateTimeBusinessStart" || runtime.memberLookup((t_16),"Name") == "DateTimeExpectedStart") {
output += "\n        ";
var t_18;
t_18 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"initialDate");
frame.set("value", t_18, true);
if(frame.topLevel) {
context.setVariable("value", t_18);
}
if(frame.topLevel) {
context.addExport("value", t_18);
}
output += "\n        ";
;
}
output += "\n        <div class=\"col col-xs-12 col-sm-";
output += runtime.suppressValue((runtime.memberLookup((t_16),"ColumnWidth") < 12?"6":runtime.memberLookup((t_16),"ColumnWidth")), env.opts.autoescape);
output += " col-md-";
output += runtime.suppressValue(runtime.memberLookup((t_16),"ColumnWidth"), env.opts.autoescape);
output += "\">\n        <label class=\"form-field\">\n          <div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_16),"Title"), env.opts.autoescape);
output += ":\n            ";
if(runtime.memberLookup((t_16),"IsRequired")) {
output += "\n            <span class=\"required-indicator\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\">*</span>\n            ";
;
}
output += "\n          </div>\n\n          <div class=\"form-input\">\n            ";
output += runtime.suppressValue((lineno = 55, colno = 42, runtime.callWrap(runtime.memberLookup((t_10),"createInputField"), "inputField[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_16,runtime.memberLookup((t_16),"Type"),runtime.contextOrFrameLookup(context, frame, "value"),null,null,null,runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "\n          </div>\n        </label>\n    </div>\n    ";
;
}
}
frame = frame.pop();
output += "\n    ";
;
}
output += "\n\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"DisplayBusinessHourStart")) {
output += "\n    <div class=\"col col-xs-12 col-sm-6 col-md-3\">\n      <label class=\"form-field\">\n        <div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"BusinessHourStart"), env.opts.autoescape);
output += ": </div>\n        <div class=\"form-input required-field\">\n          ";
output += runtime.suppressValue((lineno = 67, colno = 36, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"BusinessHourStart")])), env.opts.autoescape);
output += "\n        </div>\n      </label>\n    </div>\n    ";
;
}
output += "\n\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"DisplayBusinessHourEnd")) {
output += "\n    <div class=\"col col-xs-12 col-sm-6 col-md-3\">\n      <label class=\"form-field\">\n        <div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"BusinessHourEnd"), env.opts.autoescape);
output += ": </div>\n        <div class=\"form-input required-field\">\n          ";
output += runtime.suppressValue((lineno = 78, colno = 36, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"BusinessHourEnd")])), env.opts.autoescape);
output += "\n        </div>\n      </label>\n    </div>\n    ";
;
}
output += "\n\n\n    <!-- Category Box-->\n\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"DisplayCategoryDropdown")) {
output += "\n    <div class=\"col col-xs-12 col-sm-6 col-md-3\">\n      <label class=\"form-field\">\n        <div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Category"), env.opts.autoescape);
output += ":</div>\n        <div class=\"form-input required-field\">\n          ";
output += runtime.suppressValue((lineno = 92, colno = 36, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Comboboxes")),"CategoryID")])), env.opts.autoescape);
output += "\n        </div>\n      </label>\n    </div>\n    ";
;
}
output += "\n\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"CanAddNewItems") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"isNewObject")) {
output += "\n\n\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"DisplayItemAndCategoryAvailabilityButtons")) {
output += "\n    <!-- availability Button -->\n    <div class=\"col col-xs-12 col-sm-6 col-md-3\" id=\"availabilityModalColumn\">\n      <div id=\"availabilityModal\"></div>\n    </div>\n    ";
;
}
output += "\n\n    <!-- Scan box -->\n    <div class=\"col col-xs-12 col-sm-6 col-md-12\">\n      <div class=\"form-label form-label-select\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Scan"), env.opts.autoescape);
output += ":</div>\n      <div id=\"selectScanOrderItem\"></div>\n    </div>\n\n    ";
;
}
output += "\n\n\n\n\n  </form>\n\n  ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "\n  ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true) {
output += "\n  <div class=\"table-view pl-0 col-10 unsortable\">\n    ";
;
}
else {
output += "\n    <div class=\"table-view unsortable \">\n      ";
;
}
output += "\n      <div class=\"table-container quickrent-table\">\n        <div class=\"table-index sticky-div\">\n          <div class=\"table-row\">\n\n            <div class=\"table-cell delete-row material-icons\"  title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Delete"), env.opts.autoescape);
output += "\">\n              <div class=\"ellipsis\" style=\"min-width:15px\">&#xe5cd;</div>\n            </div>\n\n            ";
frame = frame.push();
var t_21 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns");
if(t_21) {t_21 = runtime.fromIterator(t_21);
var t_20 = t_21.length;
for(var t_19=0; t_19 < t_21.length; t_19++) {
var t_22 = t_21[t_19];
frame.set("col", t_22);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
output += "\n            ";
var t_23;
t_23 = "table-cell";
frame.set("classes", t_23, true);
if(frame.topLevel) {
context.setVariable("classes", t_23);
}
if(frame.topLevel) {
context.addExport("classes", t_23);
}
output += "\n\n            ";
if(runtime.memberLookup((t_22),"Type") == "Money") {
output += "\n            ";
var t_24;
t_24 = runtime.contextOrFrameLookup(context, frame, "classes") + " number";
frame.set("classes", t_24, true);
if(frame.topLevel) {
context.setVariable("classes", t_24);
}
if(frame.topLevel) {
context.addExport("classes", t_24);
}
output += "\n            ";
;
}
output += "\n\n            ";
if(runtime.memberLookup((t_22),"Type") == "Decimal") {
output += "\n            ";
var t_25;
t_25 = runtime.contextOrFrameLookup(context, frame, "classes") + " number";
frame.set("classes", t_25, true);
if(frame.topLevel) {
context.setVariable("classes", t_25);
}
if(frame.topLevel) {
context.addExport("classes", t_25);
}
output += "\n            ";
;
}
output += "\n\n            ";
if(runtime.memberLookup((t_22),"Type") == "Int") {
output += "\n            ";
var t_26;
t_26 = runtime.contextOrFrameLookup(context, frame, "classes") + " number";
frame.set("classes", t_26, true);
if(frame.topLevel) {
context.setVariable("classes", t_26);
}
if(frame.topLevel) {
context.addExport("classes", t_26);
}
output += "\n            ";
;
}
output += "\n\n            <div class=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
output += "\" data-column=\"";
output += runtime.suppressValue(runtime.memberLookup((t_22),"Name"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_22),"Title"), env.opts.autoescape);
output += "\">\n              <div class=\"ellipsis\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((t_22),"Title"), env.opts.autoescape);
output += "\n              </div>\n            </div>\n            <!-- <div class=\"field\" title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "i"), env.opts.autoescape);
output += "\"><div class=\"ellipsis\"><input type=\"checkbox\"></div></div> -->";
;
}
}
frame = frame.pop();
output += "\n\n            <div class=\"table-cell dropdown-info\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"UncollapseAll"), env.opts.autoescape);
output += "\">\n              <div class=\"ellipsis\" data-show-all-lower-fields>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"UncollapseAll"), env.opts.autoescape);
output += "</div>\n            </div>\n          </div>\n        </div>\n\n        <div class=\"table-body bulkedit\">\n          <div class=\"table-rows\">\n            <div class=\"table-row-group\">\n              <div class=\"new-row\">\n                <div class=\"table-row\">\n\n                  <div class=\"table-cell delete-row material-icons\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Delete"), env.opts.autoescape);
output += "\">\n                    <div class=\"ellipsis\"  style=\"min-width:32px\">&#xe5cd;</div>\n                  </div>\n                  ";
frame = frame.push();
var t_29 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"Columns");
if(t_29) {t_29 = runtime.fromIterator(t_29);
var t_28 = t_29.length;
for(var t_27=0; t_27 < t_29.length; t_27++) {
var t_30 = t_29[t_27];
frame.set("col", t_30);
frame.set("loop.index", t_27 + 1);
frame.set("loop.index0", t_27);
frame.set("loop.revindex", t_28 - t_27);
frame.set("loop.revindex0", t_28 - t_27 - 1);
frame.set("loop.first", t_27 === 0);
frame.set("loop.last", t_27 === t_28 - 1);
frame.set("loop.length", t_28);
if(runtime.memberLookup((t_30),"Combobox")) {
output += "\n                      ";
output += runtime.suppressValue((lineno = 176, colno = 59, runtime.callWrap(runtime.memberLookup((t_1),"constructTableRecordCombobox"), "combo[\"constructTableRecordCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((t_30),"Combobox"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
output += "\n                     ";
output += runtime.suppressValue((lineno = 178, colno = 43, runtime.callWrap(runtime.memberLookup((t_4),"setColumnCell"), "table[\"setColumnCell\"]", context, [t_30,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"",runtime.contextOrFrameLookup(context, frame, "window"),null,null,runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Metadata")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"))])), env.opts.autoescape);
output += "\n                    ";
;
}
output += "\n                  ";
;
}
}
frame = frame.pop();
output += "\n                  <div class=\"table-cell dropdown-info material-icons\" data-name=\"toggle-lower-field\" data-toggle-lower-field>&#xe313;</div>\n                </div>\n                <div class=\"row form-view mt-2 lower-field hide\" >\n                  ";
frame = frame.push();
var t_33 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ColumnsExtra");
if(t_33) {t_33 = runtime.fromIterator(t_33);
var t_32 = t_33.length;
for(var t_31=0; t_31 < t_33.length; t_31++) {
var t_34 = t_33[t_31];
frame.set("col", t_34);
frame.set("loop.index", t_31 + 1);
frame.set("loop.index0", t_31);
frame.set("loop.revindex", t_32 - t_31);
frame.set("loop.revindex0", t_32 - t_31 - 1);
frame.set("loop.first", t_31 === 0);
frame.set("loop.last", t_31 === t_32 - 1);
frame.set("loop.length", t_32);
output += "<div class=\"col col-xs-12 col-sm-6\">\n                    <label class=\"form-field\">\n                      <div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_34),"Title"), env.opts.autoescape);
output += "\n                        ";
if(runtime.memberLookup((t_34),"IsRequired")) {
output += "\n                        <span class=\"required-indicator\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\">*</span>\n                        ";
;
}
output += "\n                      </div>\n                      <div class=\"form-input required-field ";
output += runtime.suppressValue((runtime.memberLookup((t_34),"Type") == "Money"?"money":""), env.opts.autoescape);
output += "\" data-field-name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_34),"Name"), env.opts.autoescape);
output += "\">\n                      ";
output += runtime.suppressValue((lineno = 193, colno = 52, runtime.callWrap(runtime.memberLookup((t_10),"createInputField"), "inputField[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_34,runtime.memberLookup((t_34),"Type"),null,runtime.memberLookup((t_34),"Initial"),null,null,runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "\n                      ";
output += "\n                  </div>\n                  </label>\n                </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n              </div>\n            </div>\n          </div>\n        </div>\n        </form>\n\n        <div class=\"table-footer clearfix sticky-div stick-to-bottom\">\n          <div class=\"fake-button button button-menu no-hover no-action\">\n            <span data-select-count title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"SelectCount"), env.opts.autoescape);
output += "\">0</span>\n          </div>\n\n          ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"product") == "RentMagic") {
output += "\n          <div class=\"pull-right\">\n            <div class=\"fake-button button button-menu no-hover no-action\">\n              ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalPriceExcVAT"), env.opts.autoescape);
output += ": <span data-total-price>0.00</span>\n            </div>\n\n            <div class=\"fake-button button button-menu no-hover no-action\">\n              ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalVAT"), env.opts.autoescape);
output += ": <span data-total-btw>0.00</span>\n            </div>\n\n            <div class=\"fake-button button button-menu no-hover no-action\">\n              ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TotalPriceIncVAT"), env.opts.autoescape);
output += ": <span data-total-btw-price>0.00</span>\n            </div>\n          </div>\n          ";
;
}
output += "\n        </div>\n      </div>\n      ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent") !== null || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "windows")),"sub")) {
output += "\n      <div sidebar></div>\n      ";
;
}
output += "\n      ";
;
}
output += "\n    </div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/quickrent.html", ctx);
          }
        
          return nunjucks.render("views/content/quickrent.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/quickrent.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        