import store from "../../state/store";
import {nonActionAxiosInstance} from "./nonActionAxiosInstance";

export async function getSerialInfo({opts}) {
  const result = await nonActionAxiosInstance.post(
    `Admin/WebServices/RentWebServices.asmx/GetSerialInfo`,
    {rowRanking: null, ...opts},
    {
      params: {
        warehouse: store.state.activeWarehouse,
      },
    },
  );

  if (result.data?.GlobalActions) {
    for (const action of result.data.GlobalActions) {
      const importedModule = await import(`../actions/${action.Function}.js`);
      importedModule.default(action.Data);
    }
  }

  const {windowId} = result.config;
  const windowType = store.getters["windows/windowType"](windowId);

  if (windowId && windowType && result.data?.ComponentActions?.length) {
    // loop over every action in result.ComponentActions
    result.data?.ComponentActions.forEach((action) => {
      // call function updateRow
      store.dispatch(`${windowType}/${action.Function}`, {
        data: action.Data,
        windowId,
      });
    });
  }

  if (result.data?.IsActionResponse) {
    global.session.activeWindow.handleActionResponse(result.data);
  }

  return result.data;
}
