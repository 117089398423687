import templateMapping from "../../interface/templater/templateMapping";

export function getWindowTemplateMapping({window}) {
  if (!window) {
    return undefined;
  }
  const {Subject, Prefix} = window.output.Request;

  return templateMapping.find(
    (mapping) => mapping.Subject === Subject && mapping.Prefix === Prefix,
  )
}
