import {fetchOptions} from "./fetchOptions.js";

export const getOptions = async ({field, row}) => {
  if (field.Dropdown?.TableName) {
    return await fetchOptions({field, row});
  }

  if (field.Dropdown?.Items) {
    return await field.Dropdown.Items;
  }

  return field.Options ?? [];
};
