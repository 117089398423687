import {getExtraCriteriaFromDropdown} from "./getExtraCriteriaFromDropdown.js";
import {getComboboxItems} from "../../../services/mainRadComboBox.js";
import {getActiveWindow} from "../../window/getActiveWindow.js";

export const fetchOptions = async ({field, row}) => {
  const window = getActiveWindow();
  let primaryKeys = {};

  if (field.ReturnType) {
    primaryKeys = {
      ReturnType: field.ReturnType,
    };
  }

  if (field.Dropdown?.ExtraCriteria) {
    primaryKeys = getExtraCriteriaFromDropdown({field, row});
  }

  if (
    (Object.keys(primaryKeys).length > 0 &&
      primaryKeys.constructor === Object) ||
    field.Dropdown.TableName
  ) {
    const comboboxItems = await getComboboxItems({
      subject: window.output.Request.Subject,
      columnName: field.Dropdown.ColumnName,
      tableName: field.Dropdown.TableName,
      primaryKeys,
    });

    if (!comboboxItems?.Error) return comboboxItems;
  }
};
