import {api} from "@/services/api";
import store from "../../../state/store";

export default async function postRequest({
  Uri,
  RequestBody,
  RequestParams,
  RequestHeaders,
}) {
  const result = await api.post(Uri, RequestBody, {
    params: {
      Warehouse: store.state.activeWarehouse,
      ...RequestParams,
    },
    headers: {
      ...RequestHeaders,
    },
  });

  return result.data;
}
