<template>
  <!-- OUTER -->
  <portal to="modal">
    <transition name="modal">
      <div v-if="show" class="absolute z-[5000]">
        <!-- CONTAINER -->
        <div
          class="fixed top-0 right-0 bottom-0 left-0 overflow-x-hidden overflow-y-auto z-[11000] bg-transparent/[.40]"
          @mousedown="handleOutsideClick"
        >
          <!-- DIALOG -->
          <div
            id="modalBody"
            :class="`${computedWidth} relative rounded-md border-none dialog-shadow w-auto mx-auto my-[1.75rem] bg-white`"
          >
            <!-- HEADER -->
            <div
              v-if="showHeader === true"
              class="flex items-start justify-between !p-[1rem] bottom-border"
            >
              <h5 class="my-0 pt-[1px]">{{ title }}</h5>
              <button
                class="close !p-[1rem] !pb-[1.25rem] mt-[-1rem] mr-[-1rem] mb-[-1rem] ml-auto !text-[1.5rem] !font-bold !leading-6"
                @click="$emit('hide')"
              >
                ×
              </button>
            </div>

            <!-- BODY -->
            <div v-if="$slots['generic-body']">
              <slot name="generic-body"></slot>
            </div>
            <div v-else class="p-[1rem]">
              <slot></slot>
            </div>

            <!-- FOOTER -->
            <div v-if="showFooter === true" class="p-[1rem] top-border">
              <slot name="modal-footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import {Portal} from "portal-vue";

export default {
  Name: "RModal",
  components: {Portal},
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: true,
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    computedWidth() {
      if (this.size === "sm") return "max-w-[300px]";
      if (this.size === "md") return "max-w-[900px]";
      if (this.size === "lg") return "max-w-[800px]";
      if (this.size === "xl") return "max-w-[90%]";
      return "";
    },
  },
  created() {
    document.addEventListener("keydown", (event) => {
      const activeAlertPopups = document.getElementsByClassName("r-alert");
      if (event.key === "Escape" && !activeAlertPopups.length) {
        this.$emit("hide");
      }
    });
    this.$emit("shown");
  },
  destroyed() {
    document.removeEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        this.$emit("hide");
      }
    });
  },
  methods: {
    handleOutsideClick(event) {
      if (
        !document.getElementById("modalBody").contains(event.target) &&
        !event.target.closest(".calendars") &&
        !event.target.closest(".vs__selected")
      ) {
        this.$emit("hide");
      }
    },
  },
};
</script>

<style>
.bottom-border {
  border-bottom: 1px solid #e9ecef;
}

.top-border {
  border-top: 1px solid #e9ecef;
}

.dialog-shadow {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.modal-leave-active {
  transition: opacity 0.15s linear;
}

.modal-leave-to {
  opacity: 0;
}
</style>
