import {getInboundInvoiceItemButtons} from "../../functions/datagrid/inbound-invoice-item/getInboundInvoiceItemButtons";
import {getButtonsForWindow} from "../../functions/window/getButtonsForWindow";

export default [
  {
    Subject: "Rental.OrderItem",
    Prefix: "MultiEdit",
    template: "DatagridOrderItem",
    process: false,
    requestButtons: true,
  },
  {
    Subject: "Inbound.OrderItem",
    Prefix: "MultiEdit",
    template: "DatagridInboundOrderItem",
    process: false,
    requestButtons: true,
    getWindowButtonsFunction: getButtonsForWindow,
  },
  {
    Subject: "Rental.InvoiceItem",
    Prefix: "MultiEdit",
    template: "DatagridInvoiceItem",
    process: false,
    getWindowButtonsFunction: getButtonsForWindow,
    requestButtons: true,
  },
  {
    Subject: "Core.vw_Tasks",
    Prefix: "View",
    template: "TableTaskList",
    process: false,
    requestButtons: true,
    getWindowButtonsFunction: getButtonsForWindow,
  },
  {
    Subject: "Rental.PickListItem",
    Prefix: "Multi",
    template: "DatagridPickListItem",
    process: false,
  },
  {
    Subject: "Rental.QuotationItem",
    Prefix: "MultiEdit",
    template: "DatagridQuotationItem",
    process: false,
    requestButtons: true,
    getWindowButtonsFunction: getButtonsForWindow,
  },
  {
    Subject: "Warehouse.StockTransferItem",
    Prefix: "Multi",
    template: "DatagridStockTransferItem",
    process: false,
    requestButtons: false,
  },
  {
    Subject: "Inbound.virtual_Delivery",
    Prefix: "Multi",
    template: "DatagridInboundDelivery",
    process: false,
    requestButtons: false,
  },
  {
    Subject: "Inbound.InvoiceItem",
    Prefix: "Multi",
    template: "DatagridInboundInvoiceItem",
    process: false,
    getWindowButtonsFunction: getInboundInvoiceItemButtons,
  },
  {
    Subject: "Rental.virtual_PartialDelivery",
    Prefix: "Multi",
    template: "DatagridPartialDelivery",
    process: false,
  },
  {
    Subject: "Rental.virtual_Return",
    Prefix: "New",
    template: "DatagridReturnItem",
    process: false,
    requestButtons: false,
  },
  {
    Subject: "Rental.virtual_RailRoadTaskKanban",
    Prefix: "Multi",
    template: "KanbanTaskWrapper",
    process: false,
    requestButtons: false,
  },
];
