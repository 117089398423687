
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        const __nunjucks_template_import__dep_0 = require("../form.html");
        
        const __nunjucks_template_import__dep_1 = require("../../elements/combobox.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/modals/modalDimensionObjectSelector.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form.html", false, "views/content/modals/modalDimensionObjectSelector.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("form", t_1);
output += "\n";
env.getTemplate("views/elements/combobox.html", false, "views/content/modals/modalDimensionObjectSelector.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("combo", t_4);
output += "\n\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"DimensionObjectSelectorDialog")) {
output += "\n\t<div class=\"modal-header\">\n\t\t<h4 class=\"modal-title\" id=\"modalLable\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"DimensionObjectSelectorDialog"), env.opts.autoescape);
output += "</h5>\n\t\t<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n\t\t\t<span aria-hidden=\"true\">&times;</span>\n\t\t</button>\n\t</div>\n";
;
}
output += "\n<div id=\"modalSelectDimensionObject\" name = \"modalSelectDimensionObject\">\n\t<form class=\"grid ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "simple")?"simple":""), env.opts.autoescape);
output += "\" id=\"selectPersonForm\" name=\"modalDimensionObjectSelectorDialog\">\n\t\t<div class=\"modal-body\">\n\t\t\t<div class=\"container-fluid\">\n\t\t\t\t<div id=\"notifyDialog\" style=\"display:none;\" class=\"alert alert-warning alert-dismissible fade show\" role=\"alert\">\n\t\t\t\t\t<span id=\"notifyDialogContent\"></span>\n\t\t\t\t\t<button type=\"button\" class=\"close\" data-dismiss=\"alertPopup\" aria-label=\"Close\">\n\t\t\t\t\t\t<span aria-hidden=\"true\">&times;</span>\n\t\t\t\t\t</button>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"col-12\">\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dimensionObject")),"DimensionObjectID")) {
output += "\n\t\t\t\t\t\t\t<h4>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDimensionObjectSelectorSelectedPerson"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "description"), env.opts.autoescape);
output += "</h4>\n\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t<h4>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDimensionObjectSelectorNewPerson"), env.opts.autoescape);
output += "</h4>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t<hr>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"col-12 col-md-8\">\n\t\t\t\t\n\t\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t\t";
output += "\n\t\t\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "columns")) {
output += "\n\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_9 = runtime.contextOrFrameLookup(context, frame, "columns");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("col", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\n\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_10),"IsVisible") && runtime.memberLookup((t_10),"Ranking") > 0) {
output += "\n\t\t\t\t\t\t\t\t\t\t<div class=\"col-12 col-xs-12 col-md-4\">\n\t\t\t\t\t\t\t\t\t\t\t<label class=\"form-field\" ";
if(runtime.memberLookup((t_10),"Type") == "Boolean") {
output += " ";
;
}
output += ">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"form-label text-left\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_10),"Title"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_10),"IsRequired")) {
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"required-indicator\" title=\"\">*</span>\n\t\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_10),"combobox")) {
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"form-input combobox-scan\" id=\"parentDimensionObjectSelector\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 47, colno = 40, runtime.callWrap(runtime.memberLookup((t_4),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((t_10),"combobox")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((t_10),"Type")) {
output += "\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t<div class=\"form-input required-field ";
output += runtime.suppressValue((runtime.memberLookup((t_10),"Type") == "Money"?"money":""), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
var t_11;
t_11 = null;
frame.set("initialValue", t_11, true);
if(frame.topLevel) {
context.setVariable("initialValue", t_11);
}
if(frame.topLevel) {
context.addExport("initialValue", t_11);
}
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_10),"Initial")) {
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
var t_12;
t_12 = runtime.memberLookup((runtime.memberLookup((t_10),"Initial")),"Value");
frame.set("initialValue", t_12, true);
if(frame.topLevel) {
context.setVariable("initialValue", t_12);
}
if(frame.topLevel) {
context.addExport("initialValue", t_12);
}
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 57, colno = 39, runtime.callWrap(runtime.memberLookup((t_1),"createInputField"), "form[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_10,runtime.memberLookup((t_10),"Type"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dimensionObject")),runtime.memberLookup((t_10),"Name")),runtime.memberLookup((t_10),"Initial"),null,null,runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 61, colno = 37, runtime.callWrap(runtime.memberLookup((t_1),"createInputField"), "form[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_10,runtime.memberLookup((t_10),"Type"),runtime.memberLookup((t_10),"Value"),runtime.memberLookup((t_10),"Initial"),null,null,runtime.contextOrFrameLookup(context, frame, "window")])), env.opts.autoescape);
output += "\n\n\t\t\t\t\t\t\t\t\t\t\t\t";
;
}
;
}
output += "\n\t\t\t\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "type") == "choice") {
output += "\n\t\t\t\t\t\t\t\t\t\t<select name=\"choice\">\n\t\t\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_15 = runtime.contextOrFrameLookup(context, frame, "args");
if(t_15) {t_15 = runtime.fromIterator(t_15);
var t_14 = t_15.length;
for(var t_13=0; t_13 < t_15.length; t_13++) {
var t_16 = t_15[t_13];
frame.set("option", t_16);
frame.set("loop.index", t_13 + 1);
frame.set("loop.index0", t_13);
frame.set("loop.revindex", t_14 - t_13);
frame.set("loop.revindex0", t_14 - t_13 - 1);
frame.set("loop.first", t_13 === 0);
frame.set("loop.last", t_13 === t_14 - 1);
frame.set("loop.length", t_14);
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t<option>";
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") == "date") {
output += "\n\t\t\t\t\t\t\t\t\t\t<div class=\"date-picker\">\n\t\t\t\t\t\t\t\t\t\t\t<input type=\"text\" name=\"givendate\" autocomplete=\"no\" class=\"editable-text date\">\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t";
;
}
;
}
output += "\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t";
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<button class=\"btn btn-primary\" id=\"findRelevantDimensionObjects\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDimensionObjectSelectorSearch"), env.opts.autoescape);
output += "</button>\n\t\t\t\t\t\t<button class=\"btn btn-secondary\" id=\"clearDimensionObjectForm\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDimensionObjectSelectorClearForm"), env.opts.autoescape);
output += "</button>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"col-12 col-md-4\" id=\"containerHolder\">\n\t\t\t\t\t\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<!-- /row -->\n\t\t\t</div>\n\t\t\t<!-- /container -->\n\t\t</div>\n\t\t<div class=\"modal-footer\">\n\t\t\t<div class=\"msg-actions text-\">\n\t\t\t\t<button class=\"btn btn-default button-menu mr-auto\" data-dismiss=\"modal\" data-close>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Close"), env.opts.autoescape);
output += "</button>\n\t\t\t\t<button class=\"btn btn-primary button-menu mr-auto\" id=\"addDimensionObject\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDimensionObjectSelectorAdd"), env.opts.autoescape);
output += "</button>\n\t\t\t</div>\n\t\t</div>\n\t</form>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/modals/modalDimensionObjectSelector.html", ctx);
          }
        
          return nunjucks.render("views/content/modals/modalDimensionObjectSelector.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/modals/modalDimensionObjectSelector.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        