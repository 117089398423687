<template>
  <div>
    <component
      :is="template"
      :value="value"
      :field="fieldGenerated"
      :column="column"
      :row="row"
      @input="handleInput"
    />

    <input :id="randomID" :value="value" :name="field.name" hidden vue-input />
  </div>
</template>

<script>
import {handleLegacySelectfieldAfterPostbackFocus} from "../../functions/form/legacy-formfield/handleLegacySelectfieldAfterPostbackFocus.js";
import {processLegacyFormFieldPostback} from "../../functions/form/legacy-formfield/processLegacyFormFieldPostback.js";
import {updateValueInWindowFormObject} from "../../functions/form/legacy-formfield/updateValueInWindowFormObject.js";
import {changedFieldsAreVueFormFields} from "../../functions/form/legacy-formfield/changedFieldsAreVueFormFields.js";
import {capitalizeFirstCharInString} from "../../interface/capitalizeFirstCharInString.js";
import {processLegacySelectField} from "../../functions/form/legacy-formfield/processLegacySelectField.js";
import {processDirtyFormFields} from "../../functions/form/legacy-formfield/processDirtyFormFields.js";
import {hasSectionColumnsShift} from "../../functions/form/legacy-formfield/hasSectionColumnsShift.js";
import {getWindowFromWindowId} from "../../functions/window/getWindowFromWindowId.js";
import {getPostbackValues} from "../../services/form/getPostbackValues.js";
import {getActiveWindow} from "../../functions/window/getActiveWindow.js";
import generateRandomID from "@/util/generateRandomID";
import $ from "jquery";

export default {
  name: "FormField",
  props: {
    providedField: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    providedColumn: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    windowId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: null,
      jsonValue: null,
      initialDataSet: false,
      fieldGenerated: {},
      field: {},
      row: {},
      column: {},
    };
  },
  computed: {
    randomID() {
      return generateRandomID(8);
    },
    template() {
      const fieldName = this.field.editor ?? this.field.type;
      return () =>
        import(
          `./fields/nunjucks-wrappers/Nunjucks${capitalizeFirstCharInString({
            string: fieldName,
          })}FieldWrapper.vue`
        );
    },
  },
  watch: {
    value() {
      if (!this.initialDataSet) return;
      this.jsonValue = JSON.stringify(this.value);

      this.generateField();
      updateValueInWindowFormObject({
        value: this.value,
        name: this.field.name,
        windowId: this.windowId,
      });
    },
  },
  created() {
    this.field = this.providedField;
    this.column = this.providedColumn;
    this.value = this.field.value;
    this.generateField();
    this.row = this.getFormRow();
  },
  beforeUpdate() {
    this.initialDataSet = true;
  },
  methods: {
    generateField({column} = {}) {
      const dropdownData = column?.Dropdown ?? this.getDropdownFromWindow();
      const dropdown = dropdownData
        ? {...dropdownData, Items: dropdownData.Items ?? []}
        : null;

      this.fieldGenerated = {
        ...this.field,
        Dropdown: dropdown,
        value: this.value,
      };
    },
    getDropdownFromWindow() {
      return global.session.windows[this.field.windowid]?.output.Data.Columns[
        this.field.name
      ]?.Dropdown;
    },
    async handleInput(event) {
      if (event.json) {
        this.value = JSON.stringify(event.value);
        return;
      }
      this.value = event.value;
      $(document).find(`#${this.randomID}`).trigger("change");

      if (this.field.editor === "Select") {
        const window = getWindowFromWindowId({
          windowId: this.windowId,
          session: global.session,
        });
        await processLegacySelectField({
          column: this.column,
          field: this.field,
          vueInstance: this,
          window,
        });
      }
    },
    async processPostback({window}) {
      const vueColumns = window.vueColumns;

      const values = {};
      for (let rowcell of this.$store.state.window.output?.FullTable?.Rows[0] ??
        this.$store.state.window.output.Sub.Table.Rows[0]) {
        values[rowcell.Column.Name] = rowcell.NewValue;
      }

      values[this.field.name] = this.value;

      const result = await getPostbackValues({
        window,
        values: values,
      });

      const newColumns = result.data?.Events?.[0][1]?.Data?.Columns;
      const newSections = result.data?.Events?.[0][1]?.Data?.Sections;
      const newMetadata = result.data?.Events?.[0][1]?.Data?.Metadata;
      const newRow = result.data?.Events?.[0][1]?.Data?.Rows[0];
      const oldRow = window.output?.FullTable?.Rows[0].reduce((acc, field) => {
        acc[field.Column.Name] = field.NewValue ?? field.Value;
        return acc;
      }, {});

      if (
        changedFieldsAreVueFormFields({newRow, oldRow, window}) &&
        !hasSectionColumnsShift({newColumns, newSections, window})
      ) {
        await processLegacyFormFieldPostback({
          newMetadata,
          vueColumns,
          newColumns,
          window,
          newRow,
          oldRow,
          field: this.field,
          windowId: this.windowId,
          fieldColumn: this.column,
        });
      } else {
        await window.handleActionResponse(result?.data);
        await handleLegacySelectfieldAfterPostbackFocus({
          fieldName: this.field.name,
        });
      }

      processDirtyFormFields({window});
    },
    getFormRow() {
      return getActiveWindow().output?.Table?.Rows[0] ?? [];
    },
  },
};
</script>

<style></style>
