<template>
  <v-select
    :options="warehouses"
    :append-to-body="true"
    v-model="value"
    :clearable="false"
    id="warehouseSelector"
    :closeOnSelect="true"
    @option:selected="selectOption"
  >
    <template v-slot:option="option">
      <span>{{ option.label }}</span>
    </template>
    <div slot="no-options">{{ $store.state.translations.NoRowsFound }}</div>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import setTitle from "@/functions/setTitle";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    warehouses() {
      return this.$store.state.warehouses ?? [];
    },
    options() {
      return this.$store.state.warehouses;
    },
    activeWarehouse() {
      if (
        this.$store.state.activeWarehouse !== null &&
        this.$store.state.activeWarehouse !== ""
      ) {
        setTitle();
      }
      return this.$store.state.activeWarehouse;
    },
  },

  watch: {
    activeWarehouse() {
      this.value = this.activeWarehouse;
    },
  },
  methods: {
    async selectOption(value) {
      await this.$nextTick();
      const changeWarehouse = await this.$store.dispatch("closeAllTabs");

      if (changeWarehouse !== false) {
        this.$store.commit("setActiveWarehouse", {warehouse: value});
        setTitle();
        return;
      }

      this.$store.commit("setActiveWarehouse", {
        warehouse: this.activeWarehouse,
      });
      this.value = this.activeWarehouse;
      // click somewhere to close selectbox
      document.getElementById("warehouseSelector").parentElement.click();
    },
  },
};
</script>

<style></style>
