import {handleKeyEvent} from "./handleKeyEvent.js";
import $ from "jquery";

export const initializeKeyHandler = () => {
  let keyMap = {17: false, 32: false};

  const documentKeyHandler = (event) => {
    keyMap = handleKeyEvent({
      keyMap,
      event,
      isKeyDown: event.type === "keydown",
    });
  };

  $(document).on("keydown keyup", documentKeyHandler);

  return () => $(document).off("keydown keyup", documentKeyHandler);
};
