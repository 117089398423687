import $ from "jquery";

export const handleKeyEvent = ({keyMap, event, isKeyDown}) => {
  const {keyCode} = event;

  if (!(keyCode in keyMap)) return keyMap;

  const updatedKeyMap = updateKeyMap({keyMap, keyCode, isKeyDown});

  if (isKeyDown && isQuickOpenCombo({keyMap: updatedKeyMap})) {
    focusQuickOpen();
    return keyMap;
  }

  return updatedKeyMap;
};

const updateKeyMap = ({keyMap, keyCode, isKeyDown}) => ({
  ...keyMap,
  [keyCode]: isKeyDown,
});

const isQuickOpenCombo = ({keyMap}) => keyMap[17] && keyMap[32];

const focusQuickOpen = () => {
  $("#quickOpen input").focus();
};
